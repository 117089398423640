import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import MagicLogin from '../components/MagicLogin';
import Db from '../services/db';
import './Home.css';

function Home(){
  const [city,_city] = useState<any>();


  useEffect(()=>{
    Db.City().then((data:any)=>{
      //console.log(data)
      _city(data)
    })
  },[])
  return (
    <IonPage>
      
      <IonContent fullscreen >
        <h1 style={{textAlign:"center",paddingTop:150}}>{city?.name}</h1>  
        <div style={{textAlign:"center",paddingTop:30}}>
            <IonButton shape="round" color="secondary"  style={{width:400}} href="/app/embed">Website Embed</IonButton>
        </div>
        <div style={{textAlign:"center",paddingTop:20}}>
            <IonButton shape="round" color="secondary"  style={{width:400}} href="/app/screen">Touch Screen</IonButton>
        </div>
        <div style={{textAlign:"center",paddingTop:20}}>
            <IonButton shape="round" color="secondary"  style={{width:400}} href="/app/tv"
                onKeyDown={(e)=>{
                  if(e.keyCode == 13 || e.key == "Enter"){
                    window.location.href ="/app/tvapp"
                  }
                 
                }}
            >Digital Signage</IonButton>
        </div>
        <div style={{textAlign:"center",paddingTop:20}}>
            <IonButton shape="round" color="secondary"  style={{width:400}} href="/app/submit">Submit Something</IonButton>
        </div>
        <div style={{textAlign:"center",paddingTop:20}}>
            <IonButton shape="round" color="secondary"  style={{width:400}} href="/app/download">Download App</IonButton>
        </div>
        <div style={{textAlign:"center",paddingTop:20}}>
            <IonButton shape="round" color="medium" style={{width:400}} href="/admin">Admin</IonButton>
        </div>
        <div style={{textAlign:"center",paddingTop:20}}>
            <IonButton shape="round" color="medium" style={{width:400}} href="/hq">Yo.City HQ</IonButton>
        </div>
  
      </IonContent>
    </IonPage>
  );
};

export default Home;
