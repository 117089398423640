import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import "./CmsHome.css";
import { useEffect, useState } from "react";
import { Switch } from "react-router";
import Db from "../services/db";
import { home, homeOutline, menu, personCircleOutline } from "ionicons/icons";
import MagicLogin from "../components/MagicLogin";
import { DataGrid, GridRowsProp, GridColDef,GridCellParams } from '@mui/x-data-grid';



function CmsHome() {
const [destination, _destination] = useState<any>();

  useEffect(() => {
  

    setTimeout(() => {
    let localDest = localStorage.getItem("destination");
    let dest;
    if(localDest != null) {
       dest = JSON.parse(localDest);

         _destination(dest);
       
    }
       console.log("CmsHome useEffect",dest);
    }, 2000);


  }, []);
  return (

    <>
      <IonContent className="ion-padding admin">
        <div style={{ height: 60 }}></div>
        <div style={{backgroundColor:"white", borderRadius:10, padding:20}}>
          <div>
            <h1>Dashboard</h1>
           
           
          
          </div>
          <div style={{ height: 300, width: '100%' }}>
          
          </div>
        </div>
      </IonContent>
    </>
  );
}

export default CmsHome;
