import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import "../CmsHome.css";
import { useEffect, useState } from "react";
import { Switch, useHistory } from "react-router";
import Db from "../../services/db";
import { home, homeOutline, menu, personCircleOutline } from "ionicons/icons";
import MagicLogin from "../../components/MagicLogin";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCellParams,
  GridToolbar
} from "@mui/x-data-grid";

const columns: GridColDef[] = [ 
    { field: "id", headerName: "id", width:60, editable: false },
{ field: "published_at", headerName: "Sent", width:60,  renderCell: publishedDataGridCol  },
  { field: "title", headerName: "Title", width: 250, editable: true },
 
];

function publishedDataGridCol(params:any){
    return params.row.published_at ? (<div style={{fontWeight:900}}>Sent</div>):null
}

function Posts() {
  const history = useHistory();
  const [items, _items] = useState<any>();
  const [rows, _rows] = useState<GridRowsProp>();

  useEffect(() => {
    Db.Posts().then((data) => {
      console.log("Posts", data);
      //data.unshift({ id: "new" });
      _rows(data);
    });
  }, []);

  return (
    <>
      <IonContent className="ion-padding admin">
        <div className="content-card first">
          <div>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h1>Posts</h1>
                </IonCol>
                <IonCol style={{ textAlign: "right" }}>
                  <IonButton
                  shape="round"
                    onClick={() => {
                      history.push("/hq/post/");
                    }}
                  >
                    Add New
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div style={{ height: "75vh", width: "100%" }}>
            <DataGrid
              onCellClick={(params, event) => {
                if (params) {
                  if (params.field == "id" || params.field == "published_at") {
                    console.log(params);
                    history.push("/hq/post/" + params.row.id);
                  }
                }
              }}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              rows={rows ? rows : []}
              columns={columns}
              experimentalFeatures={{ newEditingApi: true }}
              getRowClassName={(params) => {
                if (params.row.published_at == null) {
                  return "unpublished";
                } else {
                  return "published";
                }
              }}
            />
          </div>
        </div>
      </IonContent>
    </>
  );
}

export default Posts;
