import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import "../CmsHome.css";
import { useEffect, useState } from "react";
import { Switch, useHistory, useParams } from "react-router";
import Db from "../../services/db";
import {
  arrowBack,
  checkboxOutline,
  cloudUpload,
  home,
  homeOutline,
  location,
  menu,
  personCircleOutline,
} from "ionicons/icons";
import MagicLogin from "../../components/MagicLogin";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import moment from "moment";

function DestinationUser() {
  const history = useHistory();
  const [user, _user] = useState<any>();

  const { id } = useParams<any>();

  useEffect(() => {
    let localRowsString = localStorage.getItem("destinationUsers");
    if (localRowsString != null) {
      let localRows = JSON.parse(localRowsString);
      for (let row of localRows) {
        if (row.id == id) {
          _user(row);
        }
      }
    }

    Db.DestinationUser(id).then((data: any) => {
      console.log("user by ID", data);
      _user(data);
    });
  }, []);

  function pointsRemaining() {
    let a = user && user.points_earned ? user.points_earned : 0;
    let b = user && user.points_redeemed ? user.points_redeemed : 0;

    return a - b;
  }

  function ListActions() {
    return (
      user && user.actions &&
      user.actions.map((action: any, index: number) => {

        return (
          <IonItem key={index}>
            {action.action.action_type == "region" && (
              <IonIcon icon={location} />
            )}
            {action.action.action_type == "button" && (
              <IonIcon icon={checkboxOutline} />
            )}
            {action.action.action_type == "upload" && (
              <IonIcon icon={cloudUpload} />
            )}

            {action.action.action_type == "upload" && (
              <IonAvatar 
              onClick={()=>{
                window.open(action.value, '_blank');
              }}
              slot="end">
          
                <img
                  alt="Silhouette of a person's head"
                  src={action.value}
                />
              </IonAvatar>
            )}

            <IonLabel
              onClick={() => {
                if (action && action.action && action.action.place) {
                  history.push("/hq/place/" + action?.action?.place?.id);
                } else {
                  alert("This action is not associated with a place!");
                }
              }}
            >
              <div style={{ paddingLeft:6, fontWeight: "bold" }}>
              {action?.action?.title} 
              </div>
              <div style={{ paddingLeft:12, fontSize: 12 }}>
              {action?.action?.place?.name} 
              </div>
              {action?.created && (
              <div style={{ paddingLeft:12, fontSize: 12, fontStyle:"italic" }}>
               {moment(action?.created).format("lll")}
              </div>
              )}
              
            </IonLabel>
          </IonItem>
        );
      })
    );
  }

  return (
    <>
      <IonContent className="ion-padding admin">
        <IonToolbar>
          <IonIcon
            style={{ fontSize: 40 }}
            slot="start"
            icon={arrowBack}
            onClick={() => history.goBack()}
          />
        </IonToolbar>
        <div className="content-card ">
          <div>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h1>Follower: {id}</h1>
                </IonCol>
                <IonCol style={{ textAlign: "right" }}>{}</IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div style={{ width: "100%" }}>{user?.email}</div>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardContent>
                    <div>Points Earned</div>
                    <div className="bigNumber">{user?.points_earned}</div>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard>
                  <IonCardContent>
                    <div>Points Redeemed</div>
                    <div className="bigNumber">
                      {user?.points_redeemed || 0}
                    </div>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol>
                <IonCard>
                  <IonCardContent>
                    <div>Points Remaining</div>
                    <div className="bigNumber">{pointsRemaining()}</div>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <h2>Actions</h2>
                <ul>
                  {user && user.actions && user.actions[0] && user.actions[0].action ? (
                    <ListActions />
                  ) : (
                    <></>
                  )}
                </ul>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </>
  );
}

export default DestinationUser;
