import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import Db from "../services/db";

function Tv() {
  const [city, _city] = useState<any>();
  const [lists, _lists] = useState<any>();
  const [destination, _destination] = useState<any>();

  const { search } = useLocation<any>();
  const params = new URLSearchParams(search);
  const destinationId = params.get("destination"); // bar

  useEffect(() => {
    if (destinationId) {
      Db.Destination(destinationId).then((data: any) => {
        _destination(data.city);
        console.log("destination", data);
      });
    }
    Db.City().then((data: any) => {
      //console.log(data)
      _city(data);

      Db.Categories().then((data: any) => {
        console.log("_layout", data);
        localStorage.setItem("lists", JSON.stringify(data));
        
        _lists(data);
      });
    });
  }, []);

  function Lists() {
    if (lists) {
      return lists.filter((data:any)=>{
        if(data.published_at != null){
        if(destinationId){
          if(data.destinations){
          let found = false
          for(let d of data.destinations){
            if(d.id == destinationId){
              found = true
            }
          }
          return found
          }else{
           return false
          }
        }else{
          return true
        }
      }else{
        return false
      }
      }).map((list: any) => {
        return (
          <div
            className="Tv_imageCard"
            style={{ backgroundImage: "url(" + list?.image?.url + ")" }}
          >
            <div className="Tv_imageCardContent">
              <IonButton
                style={{ flex: 1 }}
                expand="full"
                shape="round"
                color="light"
                href={"/app/listtv/" + list.id}
                onKeyDown={(e)=>{
                  if(e.keyCode == 13 || e.key == "Enter"){
                    window.location.href ="/app/listtv/" + list.id
                    localStorage.setItem("selectedList",list.id)
                  }
                 
                }}
              >
                {list.name}
              </IonButton>
            </div>

            {/* <IonCol size='3'>
                        <IonButton expand="full" shape="round" color="dark" href={"/app/listtvportrait/"+list.id}>{list.name}</IonButton>
                    </IonCol> */}
          </div>
        );
      });
    } else {
      return (
        <h3 style={{ textAlign: "center", paddingTop: 30 }}>Loading ...</h3>
      );
    }
  }

  return (
    <IonPage>
      <IonContent>
        <div style={{ backgroundColor: "#000", minHeight: "100vh" }}>
          {/* <IonGrid style={{textAlign:"center",paddingTop:30}}>
            <IonRow>
                <IonCol offset='3' size='3'  style={{width:400,  fontSize:25}} >Landscape</IonCol>
                <IonCol size='3'   style={{width:400,  fontSize:25}}>Portrait</IonCol>
            </IonRow>
        </IonGrid> */}
          <IonGrid style={{ textAlign: "center", paddingTop: 10 }}>
            <h1
              style={{
                textAlign: "left",
                padding: "0px 0 0 50px",
                color: "#ccc",
              }}
            > {destination && destination.name ? (
                <div>{destination.name}</div>
              ) : null}
              Select A List
            </h1>
            <Lists />
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Tv;
