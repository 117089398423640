// Render Prop
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router";
import Db from "../../services/db";
import "../CmsHome.css";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonRow,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { arrowBack, removeCircle, removeCircleOutline } from "ionicons/icons";
import Select from "react-select";
import MDEditor from "@uiw/react-md-editor";
import ImageSearch from "../components/ImageSearch";
import PickerMap from "../components/PickerMap";
import CmsActions from "../components/Actions";

function Event() {
  const history = useHistory();
  const { id } = useParams<any>();
  const [item, _item] = useState<any>();
  const [user, _user] = useState<any>();
  const [loading, _loading] = useState(true);

  const [showStart, _showStart] = useState<number>();
  const [showEnd, _showEnd] = useState<number>();
  const [listOptions, _listOptions] = useState<any>();
  const [description, _description] = useState<string>();
  const [destinationId, _destinationId] = useState<string>();
  const [destination, _destination] = useState<any>();

  const [listValues, _listValues] = useState<any>([]);
  const [heroImage, _heroImage] = useState<any>(null);

  const [heroImageUrl, _heroImageUrl] = useState<any>(null);

  const [presentLoading, dismissLoading] = useIonLoading();

  function getBase64Image(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const columns: GridColDef[] = [
    { field: "id", headerName: "id", editable: false },
    { field: "StartDate", headerName: "Start", width: 250, editable: true },
  ];

  useEffect(() => {
    console.log("Event", id);

    let localDestination: any;
    let localDestinationString = localStorage.getItem("destination");
    if (localDestinationString) {
      localDestination = JSON.parse(localDestinationString);
      console.log("localDestination", localDestination);
      _destination(localDestination);
    }
    _destinationId(Db.getDestination);
    Db.DestinationLists().then((data: any) => {
      let newOptionsList = [];
      for (let l of data) {
        let newListLayout = {
          value: l.id,
          label: l.name,
        };
        newOptionsList.push(newListLayout);
      }
      console.log("listsOptions", newOptionsList);
      _listOptions(newOptionsList);
    });
    _user(Db.userInfo());
    if (id) {
      Db.Event(id).then((data: any) => {
        _description(data.description);

        _item(data);
        console.log("item", data);
        _loading(false);
        let newListValues = [];
        for (let l of data.categories) {
          let newListValuesLayout = {
            value: l.id,
            label: l.name,
          };
          newListValues.push(newListValuesLayout);
        }
        _listValues(newListValues);
        if (data.heroImage) {
          let heroImageSting = data.heroImage.url;
          if (!data.heroImage.url.includes("https")) {
            heroImageSting = "https://" + heroImageSting;
          }
          _heroImageUrl(heroImageSting);
        }
      });
    } else {
      _loading(false);
    }

    console.log("id", id);
  }, []);

  function EventLists() {}

  function EventDates() {
    return item.dates.map((date: any, index: number) => {
      return (
        <div
          style={{
            backgroundColor: "#eee",
            marginBottom: 10,
            padding: 10,
            borderRadius: 10,
          }}
          key={index}
        >
          <div
            style={{
              display: "inline-block",
              width: "calc(50% - 20px)",
              verticalAlign: "bottom",
            }}
          >
            Start Date
            {showStart == index ? (
              <div>
                <input
                  className="input"
                  type="datetime-local"
                  name="StartDate"
                  onBlur={async (e: any) => {
                    if (e.target.value) {
                      presentLoading({
                        message: "Saving",
                        duration: 10000,
                      });
                      console.log(e.target.value);
                      let newItem: any = new Object(item);
                      newItem.dates[index].StartDate = moment(
                        e.target.value
                      ).format();

                      Db.UpdateEvent(newItem).then((data) => {
                        dismissLoading();
                        console.log(data);
                      });

                      // _EndDate(e.target.value);
                      // params.updateImage(e.target.files[0]);
                    }
                    _showStart(9999999);
                  }}
                />
              </div>
            ) : (
              <div
                className="input"
                onClick={() => {
                  _showStart(index);
                  _showEnd(9999999);
                }}
              >
                {moment(date.StartDate).format("MM/DD/yyyy, hh:mm A")}
              </div>
            )}
          </div>
          <div
            style={{
              display: "inline-block",
              width: "calc(50% - 20px)",
              paddingLeft: 4,
            }}
          >
            End Date
            {showEnd == index ? (
              <div>
                <input
                  className="input"
                  type="datetime-local"
                  name="EndDate"
                  onBlur={async (e: any) => {
                    if (e.target.value) {
                      presentLoading({
                        message: "Saving",
                        duration: 10000,
                      });
                      console.log(e.target.value);
                      let newItem: any = new Object(item);
                      newItem.dates[index].EndDate = moment(
                        e.target.value
                      ).format();

                      Db.UpdateEvent(newItem).then((data) => {
                        console.log(data);
                        dismissLoading();
                      });

                      // _EndDate(e.target.value);
                      // params.updateImage(e.target.files[0]);
                    }
                    _showEnd(9999999);
                  }}
                />
              </div>
            ) : (
              <div
                className="input"
                onClick={() => {
                  _showEnd(index);
                  _showStart(9999999);
                }}
              >
                {moment(date.EndDate).format("MM/DD/yyyy, hh:mm A")}
              </div>
            )}
          </div>
          <div
            style={{
              textAlign: "right",
              display: "inline-block",
              width: 36,
              verticalAlign: "bottom",
              height: 42,
            }}
          >
            <IonIcon
              onClick={() => {
                let newItem: any = new Object(item);
                let newDates = [];
                for (let d of newItem.dates) {
                  if (d.id != date.id) {
                    newDates.push(d);
                  }
                }
                newItem.dates = newDates;
                _item(newItem);
                Db.UpdateEvent(newItem).then((data) => {
                  console.log(data);
                  window.location.reload();
                });
              }}
              style={{ color: "red" }}
              size="large"
              icon={removeCircleOutline}
            />
          </div>
        </div>
      );
    });
  }

  return (
    <>
      {!loading ? (
        <IonContent className="ion-padding admin">
          <Formik
            initialValues={item ? item : {}}
            validate={(values) => {}}
            onSubmit={(values, { setSubmitting }) => {
              presentLoading({
                message: "Saving",
                duration: 10000,
              });
              //alert(JSON.stringify(values, null, 2));
              values.description = description;
              if (id) {
                Db.UpdateEvent(values).then(async (data) => {
                  console.log("updated", data);
                  setSubmitting(false);
                  if (heroImage) {
                    await Db.Upload(heroImage, "events", "heroImage", id).then(
                      () => {
                        dismissLoading();
                        console.log("Upload Img Success");
                      }
                    );
                  } else {
                    dismissLoading();
                  }
                });
              } else {
                values.destinations = [destinationId];

                Db.DraftEvent(values).then(async (data: any) => {
                  console.log("updated", data);
                  setSubmitting(false);
                  if (heroImage) {
                    await Db.Upload(
                      heroImage,
                      "events",
                      "heroImage",
                      data.id
                    ).then(() => {
                      dismissLoading();
                      console.log("Upload Img Success");
                    });
                  } else {
                    dismissLoading();
                  }

                  history.replace("/hq/event/" + data.id);
                });
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <IonGrid>
                  <IonToolbar>
                    <IonIcon
                      style={{ fontSize: 40 }}
                      slot="start"
                      icon={arrowBack}
                      onClick={() => history.goBack()}
                    />
                  </IonToolbar>
                  <IonRow>
                    <IonCol>
                      <div className="content-card ">
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              <h1>Event: {id ? id : "New"}</h1>
                            </IonCol>
                            <IonCol style={{ textAlign: "right" }}>
                              {id ? (
                                <>
                                  {item.published_at == null ? (
                                    <IonButton
                                      onClick={() => {
                                        let now = new Date();
                                        Db.UpdateEvent({
                                          id,
                                          published_at: now,
                                        }).then((data) => {
                                          console.log("updated", data);
                                          // setSubmitting(false);
                                          window.location.reload();
                                        });
                                      }}
                                      shape="round"
                                    >
                                      Publish
                                    </IonButton>
                                  ) : (
                                    <IonButton
                                      onClick={() => {
                                        Db.UpdateEvent({
                                          id,
                                          published_at: null,
                                        }).then((data) => {
                                          console.log("updated", data);
                                          // setSubmitting(false);
                                          window.location.reload();
                                        });
                                      }}
                                      shape="round"
                                    >
                                      Unpublish
                                    </IonButton>
                                  )}
                                </>
                              ) : null}
                            </IonCol>
                          </IonRow>
                        </IonGrid>

                        <div>
                          {" "}
                          <img
                            src={heroImageUrl}
                            style={{
                              height: 200,
                              borderRadius: 10,
                              border: "0px solid #eee",
                            }}
                          />
                        </div>

                        <input
                          className="img-input"
                          type="file"
                          name="heroImage"
                          onChange={async (e: any) => {
                            _heroImage(e.target.files[0]);
                            // params.updateImage(e.target.files[0]);
                            let newItem = item;
                            getBase64Image(e.target.files[0]).then(
                              (database64) => {
                                console.log(database64);
                                _heroImageUrl(database64);
                                _item(newItem);
                              }
                            );
                          }}
                        />
                        {id && (
                          <ImageSearch
                            searchString={
                              item?.name + " " + destination?.city.name
                            }
                            selected={(data: any) => {
                              let newItem = item;
                              newItem.imageUrl = data;
                              _heroImageUrl(data);
                              _item(newItem);
                              console.log(newItem);
                              Db.UpdateEvent(newItem).then((data) => {
                                console.log("images", data);
                                dismissLoading();
                              });
                            }}
                          />
                        )}
                        <ErrorMessage name="name" component="div" />
                        {/* THE FORM  */}
                        <div style={{ paddingTop: 20 }}>
                          Name:
                          <Field className="input" type="text" name="name" />
                          description:
                          <MDEditor
                            style={{ marginBottom: 10 }}
                            value={description}
                            onChange={(e) => {
                              _description(e);
                              let newItem: any = new Object(item);
                              newItem.description = e;
                              _item(newItem);
                            }}
                          />
                          <Field
                            className="input"
                            type="hidden"
                            name="description"
                          />
                          Short Description:
                          <Field
                            className="input"
                            type="text"
                            name="shortDescription"
                          />
                          Address Line 1:
                          <Field
                            className="input"
                            type="text"
                            name="address1"
                          />
                          Address Line 2:
                          <Field
                            className="input"
                            type="text"
                            name="address2"
                          />
                          City:
                          <Field className="input" type="text" name="city" />
                          State:
                          <Field className="input" type="text" name="state" />
                          Zip:
                          <Field className="input" type="text" name="zip" />
                          Website:
                          <span style={{ color: "grey" }}>
                            (https://example.com)
                          </span>
                          <Field className="input" type="text" name="website" />
                          Phone:
                          <Field
                            className="input"
                            type="text"
                            name="contactPhone"
                          />
                          Contact Name:
                          <span style={{ color: "grey" }}>
                            (for internal use)
                          </span>
                          <Field
                            className="input"
                            type="text"
                            name="contactName"
                          />
                          Contact Email:
                          <span style={{ color: "grey" }}>
                            (for internal use)
                          </span>
                          <Field
                            className="input"
                            type="text"
                            name="contactEmail"
                          />
                        </div>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "right",
                            paddingTop: 30,
                          }}
                        >
                          <IonButton
                            shape="round"
                            type="submit"
                            color="success"
                            disabled={isSubmitting}
                          >
                            Save
                          </IonButton>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol sizeLg="5">
                      {id ? (
                        <>
                          {item && item.region && item.region.lat && (
                            <PickerMap
                              saved={(data: any) => {
                                let newItem = item;
                                newItem.region = data;
                                _item(newItem);
                                console.log(newItem);
                                if (data.lat) {
                                  Db.UpdateEvent(newItem).then((data: any) => {
                                    console.log("saved", data);
                                    window.location.reload();
                                  });
                                }
                              }}
                              item={item}
                              destination={destination}
                            />
                          )}
                          <div className="content-card ">
                            <IonRow>
                              <IonCol>
                                <h1>Lists</h1>
                              </IonCol>
                              <IonCol style={{ textAlign: "right" }}></IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol>
                                {listOptions && listOptions[0] && listValues ? (
                                  <Select
                                    value={listValues}
                                    isMulti
                                    options={listOptions}
                                    onChange={(value) => {
                                      presentLoading({
                                        message: "Saving",
                                        duration: 10000,
                                      });
                                      console.log(value);
                                      _listValues(value);
                                      let newItem = item;
                                      let sendValues = [];
                                      for (let v of value) {
                                        sendValues.push(v.value);
                                      }
                                      newItem.categories = sendValues;
                                      Db.UpdateEvent(newItem).then((data) => {
                                        console.log("lists", data);
                                        dismissLoading();
                                      });
                                    }}
                                  />
                                ) : null}
                              </IonCol>
                            </IonRow>
                          </div>
                          <div className="content-card">
                            <IonRow>
                              <IonCol>
                                <h1>Dates</h1>
                              </IonCol>
                              <IonCol style={{ textAlign: "right" }}>
                                <IonButton
                                  onClick={() => {
                                    presentLoading({
                                      message: "Saving",
                                      duration: 10000,
                                    });
                                    let newRow = {
                                      StartDate: moment().format(
                                        "YYYY-MM-DDThh:mm:ss.000Z"
                                      ),
                                      EndDate: moment()
                                        .add(1, "day")
                                        .format("YYYY-MM-DDThh:mm:ss.000Z"),
                                    };
                                    let newDates = new Array(...item.dates);
                                    newDates.push(newRow);

                                    let newItem = item;

                                    newItem.dates = newDates;

                                    console.log(newDates);

                                    Db.UpdateEvent(newItem).then((data) => {
                                      console.log(data);
                                      dismissLoading();
                                      window.location.reload();
                                    });
                                    _item(newItem);
                                  }}
                                  shape="round"
                                >
                                  Add
                                </IonButton>
                              </IonCol>
                            </IonRow>

                            {item ? (
                              <div style={{ width: "100%" }}>
                                {/* <DataGrid
                    rows={item.dates}
                    columns={columns}
                    /> */}
                                <EventDates />
                              </div>
                            ) : null}
                          </div>
                        </>
                      ) : null}
                      {id && (
                        <>
                          {item && item.region && item.region.lat ? (
                            <CmsActions item={item} type="event" />
                          ) : (
                            <IonCol>
                              <div
                                style={{
                                  borderRadius: 8,
                                  overflow: "hidden",
                                  backgroundColor: "#fff",
                                  padding: 20,
                                  color: "red",
                                }}
                              >
                                Set a location to see actions
                              </div>
                            </IonCol>
                          )}
                        </>
                      )}
                      <div style={{ textAlign: "right", marginTop: 30 }}>
                        {id ? (
                          <IonButton
                            onClick={() => {
                              Db.DeleteEvent(item).then((data: any) => {
                                console.log("deleted", data);
                                window.location.href = "/hq/events";
                              });
                            }}
                            shape="round"
                            size="small"
                            color="danger"
                          >
                            Delete Event
                          </IonButton>
                        ) : (
                          <IonButton
                            onClick={() => {
                              history.replace("/hq/events");
                            }}
                            shape="round"
                            size="small"
                            color="danger"
                          >
                            Cancel
                          </IonButton>
                        )}
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </Form>
            )}
          </Formik>
        </IonContent>
      ) : null}
    </>
  );
}

export default Event;
