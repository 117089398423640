import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import Db from "../services/db";

import appleicon from "../assets/img/appleicon.png";
import androidicon from "../assets/img/androidicon.png";

import { Magic } from "magic-sdk";

const m = new Magic("pk_live_8F974A4CE6871285"); // ✨

function MagicLogin() {
  const [email, _email] = useState<any>()

  return (
    <>
    <div style={{paddingTop:100,textAlign:"center"}}>
    <div style={{marginBottom:30}}>
                        <b>Email</b>
                        <IonInput
                          className="NewForm_input"
                          value={email}
                          placeholder="email@example.com"
                          onIonChange={(e) => _email(e.detail.value)}
                        ></IonInput>
                      </div>
      <IonButton
      shape="round"
        onClick={() => {
          m.auth
            .loginWithMagicLink({ email })
            .then(async () => {
              let jwt = await m.user.generateIdToken({ lifespan: 31536000 });
              console.log("jwt",jwt)
              localStorage.setItem("jwt", jwt);
              Db.Me().then((data)=>{
                  console.log("ME",data)
                  window.location.reload();
              })
            });
        }}
      >
Login      </IonButton>  
      </div>
    </>
  );
}

export default MagicLogin;
