// Render Prop
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router";
import Db from "../../services/db";
import "../CmsHome.css";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonRow,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { arrowBack, removeCircle, removeCircleOutline } from "ionicons/icons";
import Select from "react-select";
import MDEditor from "@uiw/react-md-editor";
import PickerMap from "../components/PickerMap";

function List() {
  const history = useHistory();
  const { id } = useParams<any>();
  const [item, _item] = useState<any>();
  const [pickerItem, _pickerItem] = useState<any>();
  const [user, _user] = useState<any>();
  const [loading, _loading] = useState(true);

  const [showStart, _showStart] = useState<number>();
  const [showEnd, _showEnd] = useState<number>();
  const [listOptions, _listOptions] = useState<any>();
  const [description, _description] = useState<string>();
  const [destinationId, _destinationId] = useState<string>();
  const [destination, _destination] = useState<string>();

  const [listValues, _listValues] = useState<any>([]);
  const [heroImage, _heroImage] = useState<any>(null);

  const [heroImageUrl, _heroImageUrl] = useState<any>(null);

  const [presentLoading, dismissLoading] = useIonLoading();

  function getBase64Image(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const columns: GridColDef[] = [
    { field: "id", headerName: "id", editable: false },
    { field: "name", headerName: "Name", width: 300 },
  ];

  function Places() {
    if (item && item.linkedPlaces) {
      let rows = item.linkedPlaces;
      return (
        <div style={{ width: "100%", height: 400 }}>
          <DataGrid
            onCellClick={(params, event) => {
              if (params) {
                console.log(params);
                history.push("/hq/place/" + params.row.id);
              }
            }}
            rows={rows ? rows : []}
            columns={columns}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  function Events() {
    if (item && item.linkedEvents) {
      let rows = item.linkedEvents;
      return (
        <div style={{ width: "100%", height: 400 }}>
          <DataGrid
            onCellClick={(params, event) => {
              if (params) {
                console.log(params);
                history.push("/hq/place/" + params.row.id);
              }
            }}
            rows={rows ? rows : []}
            columns={columns}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  function MoreLists() {
    if (item && item.linkedCategories) {
      let rows = item.linkedCategories;
      return (
        <div style={{ width: "100%", height: 400 }}>
          <DataGrid
            onCellClick={(params, event) => {
              if (params) {
                console.log(params);
                history.push("/hq/list/" + params.row.id);
              }
            }}
            rows={rows ? rows : []}
            columns={columns}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  useEffect(() => {
    _user(Db.userInfo());
    let localDestination: any;
    let localDestinationString = localStorage.getItem("destination");
    if (localDestinationString) {
      localDestination = JSON.parse(localDestinationString);
      console.log("localDestination", localDestination);
      _destination(localDestination);
    }
    _destinationId(Db.getDestination);

    if (id) {
      Db.Category(id).then((data: any) => {
        _item(data);
        let pItem = data;
        pItem.region = data.center;
        _pickerItem(pItem);
        console.log("item", data);
        _loading(false);

        let newListValues = [];
        for (let l of data.linkedCategories) {
          if (l.id != id) {
            let newListValuesLayout = {
              value: l.id,
              label: l.name,
            };
            newListValues.push(newListValuesLayout);
          }
        }
        _listValues(newListValues);

        if (data && data.image) {
          let heroImageSting = data.image.url;
          if (!data.image.url.includes("https")) {
            heroImageSting = "https://" + heroImageSting;
          }
          _heroImageUrl(heroImageSting);
          if (data.description) {
            _description(data.description);
          }
        }
        Db.DestinationLists().then((data: any) => {
          let newOptionsList = [];
          for (let l of data) {
            let newListLayout = {
              value: l.id,
              label: l.name,
            };
            newOptionsList.push(newListLayout);
          }
          console.log("listsOptions", newOptionsList);
          _listOptions(newOptionsList);
        });
      });
    } else {
      _loading(false);
    }
  }, []);

  return (
    <>
      {!loading ? (
        <IonContent className="ion-padding admin">
          <Formik
            initialValues={item ? item : {}}
            validate={(values) => {}}
            onSubmit={(values, { setSubmitting }) => {
              presentLoading({
                message: "Saving",
                duration: 10000,
              });
              //alert(JSON.stringify(values, null, 2));
              if (id) {
                values.description = description;
                values.linkedEvents = undefined;
                values.linkedPlaces = undefined;
                Db.UpdateList(values).then(async (data) => {
                  console.log("updated", data);
                  setSubmitting(false);
                  if (heroImage) {
                    await Db.Upload(heroImage, "categories", "image", id).then(
                      () => {
                        dismissLoading();
                        console.log("Upload Img Success");
                      }
                    );
                  } else {
                    dismissLoading();
                  }
                });
              } else {
                values.destinations = [destinationId];

                values.description = description;

                Db.DraftList(values).then(async (data: any) => {
                  console.log("updated", data);
                  setSubmitting(false);
                  if (heroImage) {
                    await Db.Upload(
                      heroImage,
                      "categories",
                      "image",
                      data.id
                    ).then(() => {
                      dismissLoading();
                      console.log("Upload Img Success");
                    });
                  } else {
                    dismissLoading();
                  }

                  history.replace("/hq/list/" + data.id);
                });
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <IonGrid>
                  <IonToolbar>
                    <IonIcon
                      style={{ fontSize: 40 }}
                      slot="start"
                      icon={arrowBack}
                      onClick={() => history.goBack()}
                    />
                  </IonToolbar>
                  <IonRow>
                    <IonCol>
                      <div className="content-card ">
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              <h1>List: {id ? id : "New"}</h1>
                            </IonCol>
                            <IonCol style={{ textAlign: "right" }}>
                              {item ? (
                                <>
                                  {item.published_at == null ? (
                                    <IonButton
                                      onClick={() => {
                                        presentLoading({
                                          message: "Publishing",
                                          duration: 10000,
                                        });
                                        let now = new Date();
                                        Db.UpdateList({
                                          id,
                                          published_at: now,
                                        }).then((data) => {
                                          console.log("updated", data);
                                          dismissLoading();
                                          // setSubmitting(false);
                                          window.location.reload();
                                        });
                                      }}
                                      shape="round"
                                    >
                                      Publish
                                    </IonButton>
                                  ) : (
                                    <IonButton
                                      onClick={() => {
                                        presentLoading({
                                          message: "Unpublishing",
                                          duration: 10000,
                                        });
                                        Db.UpdateList({
                                          id,
                                          published_at: null,
                                        }).then((data) => {
                                          console.log("updated", data);
                                          // setSubmitting(false);
                                          dismissLoading();
                                          window.location.reload();
                                        });
                                      }}
                                      shape="round"
                                    >
                                      Unpublish
                                    </IonButton>
                                  )}
                                </>
                              ) : null}
                            </IonCol>
                          </IonRow>
                        </IonGrid>

                        <div>
                          {" "}
                          <img
                            src={heroImageUrl}
                            style={{
                              height: 200,
                              borderRadius: 10,
                              border: "0px solid #eee",
                            }}
                          />
                        </div>

                        <input
                          className="img-input"
                          type="file"
                          name="heroImage"
                          onChange={async (e: any) => {
                            _heroImage(e.target.files[0]);
                            // params.updateImage(e.target.files[0]);
                            let newItem = item;
                            getBase64Image(e.target.files[0]).then(
                              (database64) => {
                                console.log(database64);
                                _heroImageUrl(database64);
                                _item(newItem);
                              }
                            );
                          }}
                        />
                        <ErrorMessage name="name" component="div" />
                        {/* THE FORM  */}
                        <div style={{ paddingTop: 20 }}>
                          Name:
                          <Field className="input" type="text" name="name" />
                          Description:
                          <MDEditor
                            style={{ marginBottom: 10 }}
                            value={description}
                            onChange={(e) => {
                              _description(e);
                              // let newItem:any = new Object(item);
                              // newItem.description = e;
                              //_item(newItem);
                            }}
                          />
                          Icon
                          <Field className="input" as="select" name="icon">
                            {Db.icons.map((data) => {
                              return (
                                <option value={data.value}>{data.icon}</option>
                              );
                            })}
                          </Field>
                          Show On Menu Screen:
                          <Field className="input" as="select" name="showMenu">
                            <option></option>
                            <option value="true">Show</option>
                            <option value="false">Hide</option>
                          </Field>
                          Show Map Icon:
                          <Field className="input" as="select" name="showMap">
                            <option></option>
                            <option value="true">Show</option>
                            <option value="false">Hide</option>
                          </Field>
                          Default View
                          <Field className="input" as="select" name="type">
                            <option></option>
                            <option value="">List</option>
                            <option value="map">Map</option>
                          </Field>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "right",
                            paddingTop: 30,
                          }}
                        >
                          <IonButton
                            shape="round"
                            type="submit"
                            color="success"
                            disabled={isSubmitting}
                          >
                            Save
                          </IonButton>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol sizeLg="5">
                      {id ? (
                        <>
                          <PickerMap
                            saved={(data: any) => {
                              let newItem:any = {};
                              newItem.id = item.id
                              newItem.center = data;
                              // newItem.linkedEvents = undefined;
                              // newItem.linkedPlaces = undefined;
                              newItem.icon = undefined;
                              _item(newItem);
                              console.log(newItem);
                              if (data.lat) {
                                Db.UpdateList(newItem).then((data: any) => {
                                  console.log("saved", data);
                                  // window.location.reload();
                                });
                              }
                            }}
                            item={pickerItem}
                            destination={destination}
                          />

                          <div className="content-card ">
                            <IonRow>
                              <IonCol>
                                <h1>Sub-Lists</h1>
                              </IonCol>
                              <IonCol style={{ textAlign: "right" }}></IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol>
                                {listOptions && listOptions[0] && listValues ? (
                                  <Select
                                    value={listValues}
                                    isMulti
                                    options={listOptions}
                                    onChange={(value) => {
                                      presentLoading({
                                        message: "Saving",
                                        duration: 10000,
                                      });
                                      console.log(value);
                                      _listValues(value);
                                      let newItem = item;
                                      let sendValues = [];
                                      for (let v of value) {
                                        sendValues.push(v.value);
                                      }
                                      newItem.linkedCategories = sendValues;
                                      newItem.linkedEvents = undefined;
                                      newItem.linkedPlaces = undefined;
                                      Db.UpdateList(newItem).then((data) => {
                                        console.log("lists", data);
                                        dismissLoading();
                                      });
                                    }}
                                  />
                                ) : null}
                              </IonCol>
                            </IonRow>
                          </div>
                          <div className="content-card ">
                            <h1>Places</h1>
                            <Places />
                          </div>
                          <div className="content-card ">
                            <h1>Events</h1>
                            <Events />
                          </div>
                        </>
                      ) : null}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </Form>
            )}
          </Formik>
        </IonContent>
      ) : null}
    </>
  );
}

export default List;
