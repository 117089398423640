import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonPage,
    IonRouterOutlet,
    IonRow,
    IonSplitPane,
    IonTitle,
    IonToolbar,
  } from "@ionic/react";
  import { Redirect, Route } from "react-router-dom";
  import "../CmsHome.css";
  import { useEffect, useState } from "react";
  import { Switch, useHistory } from "react-router";
  import Db from "../../services/db";
  import { home, homeOutline, menu, personCircleOutline } from "ionicons/icons";
  import MagicLogin from "../../components/MagicLogin";
  import {
    DataGrid,
    GridRowsProp,
    GridColDef,
    GridCellParams,
    GridToolbar
  } from "@mui/x-data-grid";
  
  const columns: GridColDef[] = [ 
      { field: "id", headerName: "id", width:60, editable: false },
      { field: "email", headerName: "Email", width: 250, editable: false },
      { field: "points_earned", headerName: "Points Earned", width: 250, editable: false },
   
  ];
  

  
  function DestinationUsers() {
    const history = useHistory();
    const [users, _users] = useState<any>();
    const [rows, _rows] = useState<GridRowsProp>();
  
    useEffect(() => {
      let localRowsString = localStorage.getItem("destinationUsers");
      if(localRowsString){
        try{
           let localRows = JSON.parse(localRowsString);
        _rows(localRows);
        }catch{
          console.log("not local")
        }
       
      }

      Db.DestinationUsers().then((data:any)=>{
        console.log("destination users",data)
        _rows(data)
        localStorage.setItem("destinationUsers",JSON.stringify(data))
      })
    }, []);
  
    return (
      <>
        <IonContent className="ion-padding admin">
          <div className="content-card first">
            <div>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <h1>Followers</h1>
                  </IonCol>
                  <IonCol style={{ textAlign: "right" }}>
                    {/* <IonButton
                    shape="round"
                      onClick={() => {
                        history.push("/hq/user/");
                      }}
                    >
                      Add New
                    </IonButton> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <div style={{ height: "75vh", width: "100%" }}>
              <DataGrid
                onCellClick={(params, event) => {
                  if (params) {
                      console.log(params);
                      history.push("/hq/user/" + params.row.id);
                  }
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                rows={rows ? rows : []}
                columns={columns}
                experimentalFeatures={{ newEditingApi: true }}
               
              />
            </div>
          </div>
        </IonContent>
      </>
    );
  }
  
  export default DestinationUsers;
  