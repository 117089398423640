import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRoute,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "../CmsHome.css";
import { add, close } from "ionicons/icons";
import { useEffect, useState } from "react";
import Db from "../../services/db";

interface ActionsProps {
  type:string;
  item: any;
  showListsModal: boolean;
  closeModal:()=>void;
  updateItem:(item:any)=>void
}

export default function CmsSelectLists(params: ActionsProps) {

  const [lists, _lists] = useState<any>();

  useEffect(() => {
    Db.DestinationLists().then((data:any)=>{
      console.log("lists",data)
      _lists(data)
    })
  }, []);

return  (<>

<IonModal onDidDismiss={()=>{
          params.closeModal()
        }} isOpen={params.showListsModal}>
          <IonHeader style={{padding:10}}>
            <h3>
               {params.item?.name}
            </h3>
             
          </IonHeader>
          <IonContent >
            <div style={{padding:10}}>
                  <h4>Select List</h4>
                  <IonList>
                   
                
                      {lists && lists.map((list:any, index:number)=>{
                        return (
                        <IonItem 
                        onClick={()=>{
                          let newI = params.item;
                          newI.categories.push(list);
                          
                          if(params.type == "event"){
                            Db.UpdateEvent(newI);
                          }
                          if(params.type == "place"){
                            Db.UpdatePlace(newI);
                          }

                          params.updateItem(newI);
                          params.closeModal()
                        }}
                        key={index}>
                          <IonLabel>{list.name}</IonLabel>
                        </IonItem>
                        )
                      })}
                     
                  </IonList>
            </div>
      
          </IonContent>
          <IonFooter>
            <IonToolbar style={{paddingRight:10}}>
                  <IonButton onClick={()=>{
                    params.closeModal()
                  }} slot="end" shape="round" color="warning">Cancel</IonButton>
            </IonToolbar>
        
          </IonFooter>
        </IonModal>

</>)
}


