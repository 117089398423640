import React from "react";
import * as _ from "lodash";
import axios from "axios";
import uuid from 'react-uuid'
import * as Icons from "ionicons/icons";
// Working on getting back on maser --  I did it!!!


function getDestination() {
  let localUser = localStorage.getItem("user");
  let localDestinationId = localStorage.getItem("destinationId");
  let user;
  let destinationId;
  if (localUser) {
    user = JSON.parse(localUser);
  }
  if (localStorage.getItem("destinationId")) {
    destinationId = JSON.parse(localDestinationId);
  }

  if (user && user.editor && user.editor[0] && user.editor[0].destinations && user.editor[0].destinations[0]) {
    if (destinationId) {
      return destinationId
    } else {
      localStorage.setItem("destinationId", JSON.stringify(user.editor[0].destinations[0].id));
      return user.editor[0].destinations[0].id;
    }
  } else {
    return null;
  }
}

// Create `axios` instance passing the newly created `cache.adapter`
const appKey = "oavdci99rv41e2qq2at6lch41gt25vo";

const Db = {
  getDestination: getDestination,
  host: window.location.hostname == "localhost" || window.location.hostname.includes("192") || window.location.hostname.includes("10.0") ? "https://ne.yo.city/" : "https://" + window.location.hostname + "/",  //if dev
  headers: {
    'Content-Type': 'application/json'
  },
  async City() {
    let url = this.host + "city"
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async getDidToken() {
    return localStorage.getItem("jwt");
  },
  async Destination(id) {
    let url = this.host + "destinations/" + id
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Destinations() {
    let url = this.host + "destinations/"
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async ScreenLayout() {
    let url = this.host + "screen-layout"
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },

  async Categories() {
    let url = this.host + "categories"
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async AllCategories() {
    let url = this.host + "categories?_publicationState=preview"
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },

  async DestinationLists() {
    let url = this.host + "destinations/lists/" + getDestination();
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Code(id) {
    let url = this.host + "codes/" + id
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Scan(id) {
    let url = this.host + "codes/scan/" + id
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Category(id) {
    let url = this.host + "categories/" + id
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async CategoryFlat(id) {
    let url = this.host + "categories/flat/" + id
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Event(id) {
    let url = this.host + "events/" + id + "?_publicationState=preview"
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Place(id) {
    let url = this.host + "places/" + id + "?_publicationState=preview"
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Post(id) {
    let url = this.host + "posts/" + id + "?_publicationState=preview"
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Survey(id) {
    let url = this.host + "surveys/" + id
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async CreateResponse(data) {

    let url = this.host + "responses"
    return await axios.post(url, data, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });

  },
  async UpdateResponse(data) {
    let url = this.host + "responses/" + data.id
    return await axios.put(url, data, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Places() {

    let url = this.host + "destinations/allplaces/" + getDestination();
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Posts() {

    let url = this.host + "destinations/posts/" + getDestination();
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Events() {

    let url = this.host + "destinations/allevents/" + getDestination();
    return await axios.get(url, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async UpdateEvent(event, image) {
    let jwt = localStorage.getItem("jwt")
    if (jwt) {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      }
      let url = this.host + "events/editor"
      return await axios.post(url, event, {
        headers: headers,
      }).then(async (response) => {
        return response.data
      }).catch(err => {
        // alert(err)
      });
    }
  },
  async UpdatePlace(event, image) {
    let jwt = localStorage.getItem("jwt")
    if (jwt) {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      }
      let url = this.host + "places/editor"
      return await axios.post(url, event, {
        headers: headers,
      }).then(async (response) => {
        return response.data
      }).catch(err => {
        // alert(err)
      });
    }
  },
  async DeletePlace(event, image) {
    let jwt = localStorage.getItem("jwt")
    if (jwt) {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      }
      let newSend = event;
      newSend.destinations = []
      newSend.categories = []

      let url = this.host + "places/editor"
      return await axios.post(url, newSend, {
        headers: headers,
      }).then(async (response) => {
        return response.data
      }).catch(err => {
        // alert(err)
      });
    }
  },
  async DeleteEvent(event, image) {
    let jwt = localStorage.getItem("jwt")
    if (jwt) {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      }
      let newSend = event;
      newSend.destinations = []
      newSend.categories = []

      let url = this.host + "events/editor"
      return await axios.post(url, newSend, {
        headers: headers,
      }).then(async (response) => {
        return response.data
      }).catch(err => {
        // alert(err)
      });
    }
  },
  async UpdatePost(event, image) {
    let jwt = localStorage.getItem("jwt")
    if (jwt) {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      }
      let url = this.host + "posts/editor"
      return await axios.post(url, event, {
        headers: headers,
      }).then(async (response) => {
        return response.data
      }).catch(err => {
        // alert(err)
      });
    }
  },

  async UpdateList(event, image) {
    let jwt = localStorage.getItem("jwt")
    if (jwt) {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      }
      let url = this.host + "categories/editor"
      return await axios.post(url, event, {
        headers: headers,
      }).then(async (response) => {
        return response.data
      }).catch(err => {
        // alert(err)
      });
    }
  },
  async DraftEvent(event, image) {

    let url = this.host + "events/draft"
    return await axios.post(url, event, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async DraftPost(event, image) {

    let url = this.host + "posts/draft"
    return await axios.post(url, event, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },

  async DraftPlace(event, image) {

    let url = this.host + "places/draft"
    return await axios.post(url, event, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },


  async DraftList(event, image) {
    let url = this.host + "categories/draft"
    return await axios.post(url, event, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  async Upload(file, type, field, id) {
    let theId = id;
    if (_.includes(id, "-")) {
      theId = id.split("-")[1];
    }

    let photo = file;
    let formData = new FormData();

    formData.append("files", photo, photo.name);
    formData.append("ref", type);
    formData.append("field", field);
    formData.append("refId", theId);

    return await axios.post(this.host + "upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },

    }).then((response) => response.data).catch(err => {
      //alert(err)
    });

  },
  async DestinationUsers(){
    let destinationId = getDestination();
    let url = this.host + "destinations/"+ destinationId + "/users"
    let jwt = localStorage.getItem("jwt")
    if (jwt) {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      }

      return await axios.get(url, {
        headers: headers,
      }).then(async (response) => {
        return response.data
      }).catch(err => {
        // alert(err)
      });
    }
  },
  async DestinationUser(id){
    let destinationId = getDestination();
    let url = this.host + "destinations/"+ destinationId + "/users/" + id
    let jwt = localStorage.getItem("jwt")
    if (jwt) {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      }

      return await axios.get(url, {
        headers: headers,
      }).then(async (response) => {
        return response.data
      }).catch(err => {
        // alert(err)
      });
    }
  },
  async Me() {
    let url = this.host + "users/me"
    let jwt = localStorage.getItem("jwt")
    if (jwt) {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      }

      return await axios.get(url, {
        headers: headers,
      }).then(async (response) => {
        localStorage.setItem("user", JSON.stringify(response.data))
        return response.data
      }).catch(err => {
        // alert(err)
      });
    }
  },
  async updateAction(action) {
    let url = this.host + "actions/editor"
    let jwt = localStorage.getItem("jwt")
    if (jwt) {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      }

      return await axios.post(url, action, {
        headers: headers,
      }).then(async (response) => {
        return response.data
      }).catch(err => {
        // alert(err)
      });
    }
  },
  async createAction(action) {
    let url = this.host + "actions/editor/add"
    let jwt = localStorage.getItem("jwt")
    if (jwt) {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwt
      }

      return await axios.post(url, action, {
        headers: headers,
      }).then(async (response) => {
        return response.data
      }).catch(err => {
        // alert(err)
      });
    }
  },
  userInfo() {
    let localUser = localStorage.getItem("user");

    if (localUser) {
      Db.Me().then((data) => {
        localStorage.setItem("user", JSON.stringify(data))

      })
      console.log("LocalUser", JSON.parse(localUser))
      return JSON.parse(localUser);
    } else {
      return null
    }
  },
  async Track(type, key = "action", meta, refId = null) {
    let url = this.host + "analytics/track"
    let deviceId = 0;
    if (localStorage.getItem("deviceId")) {
      deviceId = localStorage.getItem("deviceId");
    } else {
      deviceId = uuid();
      localStorage.setItem("deviceId", deviceId);
    }
    console.log("meta", meta)
    let details = [];
    if (typeof meta == "object" || typeof meta == Object) {
      for (let [key, value] of Object.entries(meta)) {
        details.push({
          key,
          value
        })
      }
    } else {
      console.log("Meta not an object it is a: ", typeof meta)
    }

    return await axios.post(url,
      {
        appKey,
        type,
        refId,
        key,
        deviceId: deviceId,
        details: details
      }, {
      headers: this.headers,
    }).then(async (response) => {
      return response.data
    }).catch(err => {
      // alert(err)
    });
  },
  icons: [
    { value: "account_balance", icon: "account_balance" },
    { value: "account_balance_wallet", icon: "account_balance_wallet" },
    { value: "anchor", icon: "anchor" },
    { value: "announcement", icon: "announcement" },
    { value: "article", icon: "article" },
    { value: "beach_access", icon: "beach_access" },
    { value: "bookmarks", icon: "bookmarks" },
    { value: "card_giftcard", icon: "card_giftcard" },
    { value: "commute", icon: "commute" },
    { value: "credit_card", icon: "credit_card" },
    { value: "departure_board", icon: "departure_board" },
    { value: "design_services", icon: "design_services" },
    { value: "directions_bike", icon: "directions_bike" },
    { value: "directions_boat", icon: "directions_boat" },
    { value: "directions_bus", icon: "directions_bus" },
    { value: "directions_car", icon: "directions_car" },
    { value: "directions_railway", icon: "directions_railway" },
    { value: "directions_run", icon: "directions_run" },
    { value: "directions_walk", icon: "directions_walk" },
    { value: "event", icon: "event" },
    { value: "event_seat", icon: "event_seat" },
    { value: "explore", icon: "explore" },
    { value: "fastfood", icon: "fastfood" },
    { value: "favorite", icon: "favorite" },
    { value: "flight", icon: "flight" },
    { value: "flight_takeoff", icon: "flight_takeoff" },
    { value: "golf_course", icon: "golf_course" },
    { value: "handyman", icon: "handyman" },
    { value: "home", icon: "home" },
    { value: "hotel", icon: "hotel" },
    { value: "label", icon: "label" },
    { value: "local_activity", icon: "local_activity" },
    { value: "local_bar", icon: "local_bar" },
    { value: "local_cafe", icon: "local_cafe" },
    { value: "local_dining", icon: "local_dining" },
    { value: "local_gas_station", icon: "local_gas_station" },
    { value: "local_pizza", icon: "local_pizza" },
    { value: "local_see", icon: "local_see" },
    { value: "loyalty", icon: "loyalty" },
    { value: "map", icon: "map" },
    { value: "masks", icon: "masks" },
    { value: "menu_book", icon: "menu_book" },
    { value: "person", icon: "person" },
    { value: "pets", icon: "pets" },
    { value: "place", icon: "place" },
    { value: "pool", icon: "pool" },
    { value: "phone", icon: "phone" },
    { value: "receipt", icon: "receipt" },
    { value: "redeem", icon: "redeem" },
    { value: "restaurant", icon: "restaurant" },
    { value: "sailing", icon: "sailing" },
    { value: "schedule", icon: "schedule" },
    { value: "set_meal", icon: "set_meal" },
    { value: "shopping_bag", icon: "shopping_bag" },
    { value: "shopping_basket", icon: "shopping_basket" },
    { value: "shopping_cart", icon: "shopping_cart" },
    { value: "spa", icon: "spa" },
    { value: "sports_bar", icon: "sports_bar" },
    { value: "star", icon: "star" },
    { value: "store", icon: "store" },
    { value: "storefront", icon: "storefront" },
    { value: "tapas", icon: "tapas" },
    { value: "terrain", icon: "terrain" },
    { value: "today", icon: "today" },
    { value: "tour", icon: "tour" },
    { value: "traffic", icon: "traffic" },
    { value: "theaters", icon: "theaters" },
    { value: "wine_bar", icon: "wine_bar" },
    { value: "usd", icon: "usd" },
    { value: "ribbon", icon: "ribbon" },
    { value: "medal", icon: "medal" },
  ],
  async SearchImages(search) {
    let localDestination = localStorage.getItem("destination");
    if(localDestination){
      localDestination = JSON.parse(localDestination);
    }

    const options = {
      method: 'GET',
      url: 'https://bing-image-search1.p.rapidapi.com/images/search',
      params: { q: search , safeSearch: "Strict" , count: 16 },
      headers: {
        'X-RapidAPI-Key': '09adf44831msh57e1a4622f11e45p1e05f7jsn8f593788b2e3',
        'X-RapidAPI-Host': 'bing-image-search1.p.rapidapi.com'
      }
    };

   return axios.request(options).then(function (response) {
      return response.data.value
    }).catch(function (error) {
      console.error(error);
    });
  },
  async AddressGeoLocation(address) {
  // Look up the lat and lng of by the address
        const options = {
          method: 'GET',
          url: 'https://trueway-geocoding.p.rapidapi.com/Geocode',
          params: {address, language: 'en'},
          headers: {
            'X-RapidAPI-Key': '09adf44831msh57e1a4622f11e45p1e05f7jsn8f593788b2e3',
            'X-RapidAPI-Host': 'trueway-geocoding.p.rapidapi.com'
          }
        };

      return await axios.request(options).then(function (response) {
          console.log(response.data);
          return response.data;
        }).catch(function (error) {
          console.error(error);
        });

  }
 
};

export default Db;
