import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonSelect,
  IonSelectOption,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import Select from "react-select";
import "./CmsHome.css";
import { useEffect, useState } from "react";
import { Switch } from "react-router";
import Db from "../services/db";
import {
  calendarOutline,
  fileTrayFull,
  home,
  homeOutline,
  layersOutline,
  listOutline,
  megaphoneOutline,
  menu,
  peopleOutline,
  personCircleOutline,
  storefrontOutline,
} from "ionicons/icons";
import MagicLogin from "../components/MagicLogin";
interface IParams {
  city: any;
  user: any;
}
function CmsMenu(params: IParams) {
  const [destination, _destination] = useState<any>();

  useEffect(() => {
    console.log();
    console.log("CmsMenu", params);
    let destinationId = Db.getDestination();
    if (destinationId) {
      Db.Destination(destinationId).then((dd) => {
        console.log("destination", dd);
        _destination(dd);
        localStorage.setItem("destination", JSON.stringify(dd));
      });
    }
  }, [params.city, params.user]);

  function CityPicker() {
    return params.user.editor.map((e: any) => {
      if (e.destinations) {
        return CityPickerOptions(e);
      } else {
        return null;
      }
    });
  }

  function CityPickerOptions(editor: any) {
    if (editor && editor.destinations) {
      return editor.destinations.map((d: any) => {
        return (
          <IonSelectOption key={d.id} value={d.id}>
            {d.name}
          </IonSelectOption>
        );
      });
    } else {
      return null;
    }
  }

  return (
    <>
      <IonContent className="menu">
        <img
          src={params.city?.logo.url}
          style={{ width: "calc(100% - 70px)", margin: "30px 30px 0px 30px" }}
        />
        <IonList>
          {!params.user.editor[1] && !params.user.editor[0].destinations[1] ? (
            <div
              style={{ textAlign: "center", fontWeight: "bold", fontSize: 20 }}
            >
              {params.user.editor[0].destinations[0].name}
            </div>
          ) : (
            <div>
              <IonSelect
                interface="action-sheet"
                onIonChange={(e) => {
                  if (e.detail.value) {
                    localStorage.setItem("destinationId", e.detail.value);
                    console.log("qDate", e);
                    window.location.reload();
                  }
                }}
                style={{
                  fontSize: 20,
                  borderRadius: 30,
                  backgroundColor: "white",
                  margin: 30,
                }}
                //value={destination?.city.id}
                placeholder={destination?.city?.name}
              >
                <CityPicker />
              </IonSelect>
            </div>
          )}

          <IonMenuToggle auto-hide="false">
            <IonItem className="menu" lines="none" button routerLink="/hq/home">
              <IonIcon slot="start" icon={homeOutline}></IonIcon>
              <IonLabel>Home</IonLabel>
            </IonItem>
            <IonItem
              className="menu"
              lines="none"
              button
              routerLink="/hq/users"
            >
              <IonIcon slot="start" icon={peopleOutline}></IonIcon>
              <IonLabel>Followers</IonLabel>
            </IonItem>
            <IonItem
              className="menu"
              lines="none"
              button
              routerLink="/hq/lists"
            >
              <IonIcon slot="start" icon={listOutline}></IonIcon>
              <IonLabel>Lists</IonLabel>
            </IonItem>
            <IonItem
              className="menu"
              lines="none"
              button
              routerLink="/hq/events"
            >
              <IonIcon slot="start" icon={calendarOutline}></IonIcon>
              <IonLabel>Events</IonLabel>
            </IonItem>
            <IonItem
              className="menu"
              lines="none"
              button
              routerLink="/hq/places"
            >
              <IonIcon slot="start" icon={storefrontOutline}></IonIcon>
              <IonLabel>Places</IonLabel>
            </IonItem>
            <IonItem
              className="menu"
              lines="none"
              button
              routerLink="/hq/posts"
            >
              <IonIcon slot="start" icon={megaphoneOutline}></IonIcon>
              <IonLabel>Messages</IonLabel>
            </IonItem>
            <IonItem
              className="menu"
              lines="none"
              button
              routerLink="/hq/integrations"
            >
              <IonIcon slot="start" icon={fileTrayFull}></IonIcon>
              <IonLabel>Integrations</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </>
  );
}

export default CmsMenu;
