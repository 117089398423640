// Render Prop
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router";
import Db from "../../services/db";
import "../CmsHome.css";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonRow,
  useIonLoading,
} from "@ionic/react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { removeCircle, removeCircleOutline } from "ionicons/icons";
import Select from "react-select";
import MDEditor from "@uiw/react-md-editor";

function Post() {
  const history = useHistory();
  const { id } = useParams<any>();
  const [item, _item] = useState<any>();
  const [user, _user] = useState<any>();
  const [loading, _loading] = useState(true);

  const [showStart, _showStart] = useState<number>();
  const [showEnd, _showEnd] = useState<number>();
  const [listOptions, _listOptions] = useState<any>();
  const [description, _description] = useState<string>();
  const [destinationId, _destinationId] = useState<string>();

  const [listValues, _listValues] = useState<any>([]);
  const [heroImage, _heroImage] = useState<any>(null);

  const [heroImageUrl, _heroImageUrl] = useState<any>(null);

  const [presentLoading, dismissLoading] = useIonLoading();

  function getBase64Image(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const columns: GridColDef[] = [
    { field: "id", headerName: "id", editable: false },
    { field: "StartDate", headerName: "Start", width: 250, editable: true },
  ];

  useEffect(() => {
    _destinationId(Db.getDestination);
    

    Db.DestinationLists().then((data: any) => {
      let newOptionsList = [];
      for (let l of data) {
        let newListLayout = {
          value: l.id,
          label: l.name,
        };
        newOptionsList.push(newListLayout);
      }
      console.log("listsOptions", newOptionsList);
      _listOptions(newOptionsList);
    });
    _user(Db.userInfo());
    if (id) {
      Db.Post(id).then((data: any) => {
        _item(data);
        console.log("item", data);
        _loading(false);
        let newListValues = [];
        for (let l of data.categories) {
          let newListValuesLayout = {
            value: l.id,
            label: l.name,
          };
          newListValues.push(newListValuesLayout);
        }
        _listValues(newListValues);

        if(data.heroImage && data.heroImage.url){

       
        let heroImageSting = data.heroImage.url;
        if (!data.heroImage.url.includes("https")) {
          heroImageSting = "https://" + heroImageSting;
        }
        
        _heroImageUrl(heroImageSting);
       }
        _description(data.description)
      });
    } else {
      _loading(false);
    }
  }, []);

  function PostLists() {}


  return (
    <>
      {!loading ? (
        <IonContent className="ion-padding admin">
          <Formik
            initialValues={item ? item : {}}
            validate={(values) => {}}
            onSubmit={(values, { setSubmitting }) => {
              presentLoading({
                message: "Saving",
                duration: 10000,
              });
              //alert(JSON.stringify(values, null, 2));
              values.description = description
              if (id) {
                Db.UpdatePost(values).then(async (data) => {
                  console.log("updated", data);
                  setSubmitting(false);
                  if (heroImage) {
                    await Db.Upload(heroImage, "posts", "heroImage", id).then(
                      () => {
                        dismissLoading();
                        console.log("Upload Img Success");
                      }
                    );
                  } else {
                    dismissLoading();
                  }
                });
              } else {

                values.destinations = [destinationId];

                Db.DraftPost(values).then(async (data: any) => {
                  console.log("updated", data);
                  setSubmitting(false);
                  if (heroImage) {
                    await Db.Upload(
                      heroImage,
                      "places",
                      "heroImage",
                      data.id
                    ).then(() => {
                      dismissLoading();
                      console.log("Upload Img Success");
                    });
                  } else {
                    dismissLoading();
                  }

                  history.replace("/hq/post/" + data.id);
                });
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <div className="content-card first">
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              <h1>Post: {id ? id : "New"}</h1>
                            
                            </IonCol>
                            <IonCol style={{ textAlign: "right" }}>
                              {item  && id? (
                                <>
                                  {item.published_at == null ? (
                                    <IonButton
                                      onClick={() => {
                                        presentLoading({
                                          message: "Sending",
                                          duration: 10000,
                                        });
                                        let now = new Date();
                                        let newPost = item

                                        newPost.published_at = now;
                                        Db.UpdatePost(newPost).then((data) => {
                                          console.log("updated", data);
                                          // setSubmitting(false);
                                          dismissLoading();
                                          window.location.reload();
                                        });
                                      }}
                                      shape="round"
                                    >
                                      Send
                                    </IonButton>
                                  ) : (
                                    <IonButton
                                      onClick={() => {
                                        presentLoading({
                                          message: "unpublishing",
                                          duration: 10000,
                                        });
                                        Db.UpdatePost({
                                          id,
                                          published_at: null,
                                        }).then((data) => {
                                          console.log("updated", data);
                                          dismissLoading();
                                          // setSubmitting(false);
                                          window.location.reload();
                                        });
                                      }}
                                      shape="round"
                                    >
                                      Unpublish
                                    </IonButton>
                                  )}
                                </>
                              ) : null}
                            </IonCol>
                          </IonRow>
                        </IonGrid>

                        <div>
                          {" "}
                          <img
                            src={heroImageUrl}
                            style={{
                              height: 200,
                              borderRadius: 10,
                              border: "0px solid #eee",
                            }}
                          />
                        </div>

                        <input
                          className="img-input"
                          type="file"
                          name="heroImage"
                          onChange={async (e: any) => {
                            _heroImage(e.target.files[0]);
                            // params.updateImage(e.target.files[0]);
                            let newItem = item;
                            getBase64Image(e.target.files[0]).then(
                              (database64) => {
                                console.log(database64);
                                _heroImageUrl(database64);
                                _item(newItem);
                              }
                            );
                          }}
                        />
                        <ErrorMessage name="name" component="div" />
                        {/* THE FORM  */}
                        <div style={{ paddingTop: 20 }}>
                          Title:
                          <Field className="input" type="text" name="title" />
                        

                          Body:
                          <Field
                            className="input"
                            type="text"
                            name="body"
                          />

                        </div>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "right",
                            paddingTop: 30,
                          }}
                        >
                          <IonButton
                            shape="round"
                            type="submit"
                            color="success"
                            disabled={isSubmitting}
                          >
                            Save
                          </IonButton>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol sizeLg="5">
                     
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </Form>
            )}
          </Formik>
        </IonContent>
      ) : null}
    </>
  );
}

export default Post;
