import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import "../CmsHome.css";
import { useEffect, useState } from "react";
import { Switch } from "react-router";
import Db from "../../services/db";
import { home, homeOutline, menu, personCircleOutline } from "ionicons/icons";

import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCellParams,
} from "@mui/x-data-grid";
import { CopyBlock } from "react-code-blocks";

function Integrations() {
  const [token, _token] = useState<string>();
  const [showToken, _showToken] = useState<boolean>(false);
  const [destinationId, _destinationId] = useState<string>();

  useEffect(() => {
    _destinationId(Db.getDestination());
    console.log("Page", "Integrations");
    // Get did token from magic login
    Db.getDidToken().then((didToken) => {
      if (didToken) {
        _token(didToken);
      }
    });
  }, []);

  return (
    <>
      <IonContent className="ion-padding admin">
        <div style={{ height: 60 }}></div>
        <div
          style={{ backgroundColor: "white", borderRadius: 10, padding: 20 }}
        >
          <div>
            <h1>Integrations</h1>
            Here you can find information on how to integrate with other systems
          </div>
          <div style={{  width: "100%", marginTop: 20 }}>
            <h1>
              Destination ID: <b>{destinationId && destinationId}</b>
            </h1>
            <IonButton
              size="small"
              shape="round"
              onClick={() => _showToken(!showToken)}
            >
              Show API Key
            </IonButton>
            {showToken && (
              <div
                style={{
                  border: "#eee solid 2px",
                  padding: 10,
                  borderRadius: 5,
                  backgroundColor:"#FAFAFA"
                }}
              >
             {token ? (
                <CopyBlock
                text={token}
                language={"html"}
                showLineNumbers={false}
                theme={"dracula"}
              />
              ) : "No token"}
              </div>
            )}
          
          </div>  
          <div>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonCard
                     style={{backgroundColor:"#fff",height:320}}
                     onClick={() => {
                      // go to zapier page
                      window.open("https://zapier.com/developer/public-invite/166841/02cbdf250a04664b521a4fe154be8d4c/");
                     } }
                     >
                      <img src={"/assets/zapier.jpg"} />
                      <IonCardContent>
                        Zapier empowers you to automate your work across 5,000+ apps—so you can move forward, faster.
                      <div style={{fontSize:"small",fontStyle: "italic",marginTop:5 }}>
                     Integration requires a Zapier account.
                      </div>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                  <IonCard
                  routerLink="/hq/integration/display"
                     style={{backgroundColor:"#fff",height:320}}
                     onClick={() => {
                      // go to Intregration Display page
                     
                     } }
                     >
                      <img src={"/assets/display.jpg"} />
                      <IonCardContent>
                        Display your content in a beautiful way. With digital signage, turn any screen into a powerful information terminal with an Amazon Fire Stick.
                      <div style={{fontSize:"small",fontStyle: "italic",marginTop:5 }}>
                     Display content on an Amazon Fire Stick
                      </div>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                  <IonCard
                    routerLink="/hq/integration/website"
                     style={{backgroundColor:"#fff",height:320}}
                     onClick={() => {
                      // go to integration Website page
                     // window.location.href = "/hq/integration/website";
                     } }
                     >
                      <img src={"/assets/embed.jpg"} />
                      <IonCardContent>
                        Display your events and lists on any website and integrate with your existing websites style. 
                      <div style={{fontSize:"small",fontStyle: "italic",marginTop:5 }}>
                    
                      </div>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                  <IonCard
                    routerLink="/hq/integration/submit"
                     style={{backgroundColor:"#fff",height:320}}
                     onClick={() => {
                      // go to integration Website page
                     // window.location.href = "/hq/integration/website";
                     } }
                     >
                      <img src={"/assets/submit.jpg"} />
                      <IonCardContent>
                        Allow others to submit events and places.  Once they are submitted and once approved automatically displayed.
                        <div style={{fontSize:"small",fontStyle: "italic",marginTop:5 }}>
                        Submit Something
                      </div>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                  <IonCard
                    routerLink="/hq/integration/views"
                     style={{backgroundColor:"#fff",height:320}}
                     onClick={() => {
                      // go to integration Website page
                     // window.location.href = "/hq/integration/website";
                     } }
                     >
                      <img src={"/assets/views.jpg"} />
                      <IonCardContent>
                        Share items and lists in various different layouts that can be used as either a landing page or embedded into your sites.
                        <div style={{fontSize:"small",fontStyle: "italic",marginTop:5 }}>
                        View Lists 
                      </div>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                  <IonCard
                    routerLink="/hq/integration/import"
                     style={{backgroundColor:"#fff",height:320}}
                     onClick={() => {
                      // go to integration Website page
                     // window.location.href = "/hq/integration/website";
                     } }
                     >
                      <img src={"/assets/import.jpg"} />
                      <IonCardContent>
                        Import data from other systems here with a CSV file.
                        <div style={{fontSize:"small",fontStyle: "italic",marginTop:5 }}>
                        Import Data
                      </div>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol></IonCol>
                  <IonCol></IonCol>
                </IonRow>
              </IonGrid>
            </div>
        </div>
      </IonContent>
    </>
  );
}

export default Integrations;
