import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import Db from "../services/db";
import _ from "lodash";
import "./ListTvPortrait.css";
import moment from "moment";
import { arrowBack, arrowBackCircleOutline, colorFill } from "ionicons/icons";
import QRCode from "react-qr-code";

let inactiveTime: any;
let minutes = 10;
let speed = 100;

function ListTvPortrait(props: any) {

  

  const [city, _city] = useState<any>();
  const [list, _list] = useState<any>();
  const [items, _items] = useState<any>();
  const [selectedItem, _selectedItem] = useState<any>();
  const [interval, _interval] = useState<any>();

  const scrollRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const { id } = useParams<any>();

  // function resetTimer(timer:any) {
  //   console.log("timereset")
  //   clearTimeout(timer);
  //   inactiveTime = setTimeout(() => {
  //     selectNextEvent();
  //   }, minutes * 60 * 1000);
  // }

  useEffect(() => {
    Db.City().then((data: any) => {
      _city(data);
    });

    Db.ScreenLayout().then((data: any) => {
      minutes = data.ListTimeout;
      // resetTimer(inactiveTime)
      if(data.speed){
        speed = data.speed
      } 
    });
    if (id) {
      Db.Category(id).then((data: any) => {
        console.log("list", data);
        let listThings: any = [];

        for (let e of data.linkedEvents) {
          if (moment() <= moment(e.endDate)) {
            listThings.push(e);
          }
        }
        for (let p of data.linkedPlaces) {
          listThings.push(p);
        }
        for (let p of data.linkedPosts) {
          listThings.push(p);
        }

        _list(data);
        let things = _.orderBy(_.orderBy(listThings, "name"), "startDate");
        _items(things);
        _selectedItem(things[0]);
        console.log("listThings", listThings);

        let vpheight = document.documentElement.clientWidth * 0.225;
        let viewHeight = document.documentElement.clientWidth;
        // console.log("viewHeight",viewHeight,"vpheight",vpheight)
        let length = listThings.length * vpheight;
        let position = 0;
        _interval(
          setInterval(() => {
            position += 1;
            if (scrollRef.current) {
              scrollRef.current.scrollBy(1,0);
            }
            let itemid = position / (_.round(vpheight));

            if (Number.isInteger(itemid)) {
              let item = things[itemid];
              _selectedItem(item);
              // console.log("Item", item)
              // console.log("selectedItem",id)
            }

            if (position >= length) {
              position = 0;
              _selectedItem(things[0]);

              if (scrollRef.current) {
                scrollRef.current.scrollTo(0, 0);
              }
            }
            // console.log("position", position , "length", length, "id", id)
          }, speed)
        );

        setTimeout(() => {
          window.location.reload();
        }, 24 * 60 * 60 * 1000);
      });
    }

    return function cleanup() {
      //clearInterval(interval)
    };
  }, []);

  function List() {
    return items.map((item: any) => {
      let heroImage = item.heroImage?.url;
      if (
        item.heroImage &&
        item.heroImage.formats &&
        item.heroImage.formats.small
      ) {
        heroImage = item.heroImage.formats.small.url;
      }

      return (
        <div className="ListTvPort_items_item">
                    <div
            onClick={() => {
              _selectedItem(item);
            }}
            className="ListTvPort_items_item_box"
            style={{
              backgroundImage: heroImage ? "url(" + heroImage + ")" : "",
            }}
          >
         
          </div>
          <div className="ListTvPort_items_item_dates">
            <IonGrid>
              <IonRow>
                <IonCol size="3">
                  <IonRow>
                    <IonCol>
                      <div style={{ fontWeight: "bold",textAlign:"center",marginTop:-5 }}>
                        {item.startDate
                          ? moment(item.startDate).format("MMM")
                          : null}
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <div
                        style={{
                          textAlign:"center",
                          fontWeight: "bold",
                          fontSize: "2.5vw",
                          marginTop:-15
                        }}
                      >
                        {item.startDate
                          ? moment(item.startDate).format("D")
                          : null}
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size="9">
                  <div className="ListTvPort_items_item_title">{item.name}</div>
                </IonCol>
              </IonRow>
            </IonGrid>

            
          </div>

        </div>
      );
    });
  }

  if (!id) {
    return <>Need ID!!</>;
  } else {
    if (list && list.name) {
      return (
        <IonPage >
          <IonHeader></IonHeader>
          <IonContent  id="listtvport" fullscreen>
            <div
              onMouseMove={() => {
                //resetTimer(inactiveTime)
              }}
              onMouseDown={() => {
                //resetTimer(inactiveTime)
              }}
            >
              <IonGrid  no-padding>
                <IonRow  no-padding>
                  <IonCol>
                    <div
                      className="ListTvPort_item_image_box"
                      style={{
                        backgroundImage: selectedItem?.heroImage
                          ? "url(" + selectedItem?.heroImage.url + ")"
                          : "",
                      }}
                    ></div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol style={{transform: "(90deg) !important"}}>
                    <div className="ListTvPort_item_details_box">
                      {selectedItem ? (
                        <>
                          <div className="ListTvPort_item_details_title">
                            {selectedItem.name}
                          </div>
                          {selectedItem.startDate ? (
                            <div className="ListTvPort_item_details_dates">
                              <div className="ListTvPort_item_details_dates_box">
                                <div className="ListTvPort_item_details_dates_title">
                                  from
                                </div>
                                <div className="ListTvPort_item_details_dates_date">
                                  {moment(selectedItem.startDate).format("l")}
                                </div>
                                <div className="ListTvPort_item_details_dates_title">
                                {selectedItem.hideTimes != true ? (
                                   moment(selectedItem.startDate).format(
                                    "h:mm:ss a"
                                  )
                                  )
                                 : null}
                                </div>
                              </div>
                              <div className="ListTvPort_item_details_dates_box">
                                <div className="ListTvPort_item_details_dates_title">
                                  to
                                </div>
                                <div className="ListTvPort_item_details_dates_date">
                                  {moment(selectedItem.endDate).format("l")}
                                </div>
                                <div className="ListTvPort_item_details_dates_title">
                                {selectedItem.hideTimes != true ? (
                                   moment(selectedItem.endDate).format(
                                    "h:mm:ss a"
                                  )
                                  )
                                 : null}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="ListTvPort_item_details_address">
                            <IonRow>
                              <IonCol>
                              {selectedItem?.address1}
                            <br />
                            {selectedItem.address2 ? (
                              <>
                                {selectedItem.address2}
                                <br />
                              </>
                            ) : null}
                            {selectedItem.city ? (
                              <>{selectedItem.city}, </>
                            ) : null}
                            {selectedItem.state ? (
                              <>{selectedItem.state} </>
                            ) : null}
                            {selectedItem.zip ? <>{selectedItem.zip} </> : null}
                              </IonCol>
                              <IonCol style={{textAlign:"right"}}>
                               {selectedItem.website ? (
                            <QRCode value={selectedItem?.website} size={100}/>
                            ):null}
                              </IonCol>
                            </IonRow>
                           
                          </div>
                          <div className="ListTvPort_item_details_text">
                            {selectedItem?.shortDescription}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    size="4"
                    style={{ margin: "-8px 0px -16px 0px" }}
                    no-padding
                  >
                    <div className="ListTvPort_items_box" ref={scrollRef}>
                      <div className="ListTvPort_items_list">
                        {items ? (
                          <>
                            <List />
                            <List />
                          </>
                        ) : null}
                        <div id="ifScroll-container" style={{ color: "white" }}>
                          more
                        </div>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonContent>
        </IonPage>
      );
    } else {
      return (
        <IonPage>
          <IonHeader></IonHeader>
          <IonContent id="listtv" fullscreen>
            <h1 style={{ textAlign: "center", color: "white" }}>Loading...</h1>
          </IonContent>
        </IonPage>
      );
    }
  }
}

export default ListTvPortrait;
