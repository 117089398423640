import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import "../CmsHome.css";
import { useEffect, useState } from "react";
import { Switch, useHistory } from "react-router";
import Db from "../../services/db";
import { ellipse, ellipseOutline, home, homeOutline, menu, personCircleOutline } from "ionicons/icons";
import MagicLogin from "../../components/MagicLogin";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCellParams,
  GridToolbar
} from "@mui/x-data-grid";
import CmsSelectLists from "../components/SelectList";

const columns: GridColDef[] = [ 
  { field: "published_at", headerName: "Live", width:60,  renderCell: publishedDataGridCol  },
    { field: "id", headerName: "id", width:60, editable: false },

  { field: "name", headerName: "Name", width: 250, editable: true },
  { field: "lists", headerName: "Lists", width: 250, editable: false, valueGetter: listsDataGridCol  },

 
];

function publishedDataGridCol(params:any){
  return params.row.published_at ? (<IonIcon size="large" icon={ellipse} color="secondary" />):(
    <>
    <IonIcon size="large" icon={ellipseOutline} /> 
    </>
  )
}

function listsDataGridCol(params:any){
return params.row.categories.map((category:any,index:number)=>{
    return category.name
})
}

function Places() {
  const history = useHistory();
  const [items, _items] = useState<any>();
  const [rows, _rows] = useState<GridRowsProp>();

  const [showListsModal, _showListsModal] = useState(false);
  const [selectedItem, _selectedItem] = useState<any>();

  useEffect(() => {
    Db.Places().then((data) => {
      console.log("Places", data);
      //data.unshift({ id: "new" });
      _rows(data);
    });
  }, []);

  return (
    <>
      <IonContent className="ion-padding admin">
        <div className="content-card first">
          <div>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h1>Places</h1>
                </IonCol>
                <IonCol style={{ textAlign: "right" }}>
                  <IonButton
                  shape="round"
                    onClick={() => {
                      history.push("/hq/place/");
                    }}
                  >
                    Add New
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div style={{ height: "75vh", width: "100%" }}>
            <DataGrid
              onCellClick={(params, event) => {
                if (params) {
                  if (params.field == "id" || params.field == "name") {
                    console.log(params);
                    history.push("/hq/place/" + params.row.id);
                  }
                  if(params.field == "published_at" && !params.row.published_at ){
                    let newRow = params.row
                    let now = new Date();
                    newRow.published_at = now
                    Db.UpdatePlace(newRow);
                  }
                  if(params.field == "lists" ){
                    _showListsModal(true);
                    _selectedItem(params.row)
                  }
                }
              }}
              onCellEditStop={(params, event) => {
                console.log(params);
               
                  Db.UpdatePlace(params.row).then((data) => {
                    console.log("updated", data);
                  });
              
                
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'published_at', sort: 'asc' },{ field: 'id', sort: 'desc' }],
                },
              }}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              rows={rows ? rows : []}
              columns={columns}
              experimentalFeatures={{ newEditingApi: true }}
              getRowClassName={(params) => {
                if (params.row.published_at == null) {
                  return "unpublished";
                } else {
                  return "published";
                }
              }}
            />
          </div>
        </div>
        <CmsSelectLists 
              type="place"
              item={selectedItem} 
              showListsModal={showListsModal} 
              closeModal={()=>{
                _showListsModal(false)
              }} 
              updateItem={(item)=>{
                _selectedItem(item)
              }}
              />
      </IonContent>
    </>
  );
}

export default Places;
