import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import Db from '../services/db';
import './Home.css';
import { CopyBlock } from "react-code-blocks";



function Embed(){
  const [city,_city] = useState<any>();
  const [lists, _lists] = useState<any>();
  const [list, _list] = useState<any>(1);

  let destinationId = Db.getDestination();

  useEffect(()=>{
    Db.City().then((data:any)=>{
      //console.log(data)
      _city(data)

      Db.Categories().then((data:any)=>{
        console.log("list", data)
        _lists(data)
      })
    })
  },[])

 function ListsOptions(){
   if(lists){
   return lists.filter((item:any)=>{
    if(destinationId){
        return item.destinations && item.destinations[0] && item.destinations[0].id === destinationId
    }else{
      return true
    }
   }).map((i:any)=>{
    return(
      <option  value={i.id}>{i.name}</option>
    )
   })
  }else{
    return null;
  }
 }

  const IndexCode = `<script src="https://yo.city/embed.js"></script>
<script>
    var yc_app = "`+ window.location.hostname.split(".")[0] +`";
</script>`;

const ListCode = `<div id="yc_list" 
    list="`+list+`" 
    item-link="example-item-page?yc-item=[item-id]">
</div>`;

const ItemCode = `<div id="yc_item"></div>`;

  return (
    <IonPage >
      <IonHeader>

      </IonHeader>
      <IonContent fullscreen >
        <IonGrid>
          <IonRow>
            <IonCol offsetSm='3' sizeSm='6'>
                 <div style={{paddingTop:30, fontSize:30, marginBottom:20}}>
          Embed Details
        </div>
          <div>
            Add the following script tag to the header of your website
            <div style={{backgroundColor:"white", marginTop:10, marginBottom:30}}>
            <CopyBlock
                text={IndexCode}
                language={"html"}
                showLineNumbers={true}
                theme={"dracula"}
              />
              </div>
          </div>   
          <div>
            <h4>Create two pages</h4>
              One page for the list that will be embedded and one page to display the items when they are selected. 
              <div style={{fontStyle: "italic"}}>Note: If you are embedding multiple lists, you may reuse the same item page for multiple lists. </div>
              
              <br/>
            <h4>Item Page</h4>
            The item page is where visitors will be sent when they click on an item in a list. In the item page paste the following code where you would like the item to show up. <br />

            <div style={{backgroundColor:"white", marginTop:10, marginBottom:30}}>
            <CopyBlock
                text={ItemCode}
                language={"html"}
                showLineNumbers={true}
                theme={"dracula"}
              />
              </div>

              <h4>List Page</h4>
            On the list page paste the following code where you would like the list to show up and change the following parameters. <br />
            <ul>
              <li>list="{list}"
                <ul>
                  <li>
                    list should be set to the ID of the list that you would like to display
                  </li>
                  <li>
                    The list ID can be found under Categories in admin panel
                  </li>
                </ul>
              </li>
              <li>item-link=""
                <ul>
                  <li>
                    Item link represents the link that will be passed to the list items when clicked
                  </li>
                  <li>
                    This link should point to the item page that was created
                  </li>
                  <li>
                    The [item-id] string will be replaced by the ID of the item in the link
                  </li>
                  <li>
                    The yc-item parameter is used by the item page to determine what item to show
                  </li>
                </ul>
              </li>
            </ul>
            List: &nbsp;
            <select
            value={list}
            onChange={(e)=>{
       
                _list(e.currentTarget.value)
              
            }} name="cars" id="cars">
              <ListsOptions />
              </select> 
            <div style={{backgroundColor:"white", marginTop:10, marginBottom:30}}>
            <CopyBlock
                text={ListCode}
                language={"html"}
                showLineNumbers={true}
                theme={"dracula"}
              />
              </div>
          </div>   
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Embed;
