import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import Db from "../services/db";
import _ from "lodash";
import "./List.css";
import moment from "moment";
import { arrowBack, arrowBackCircleOutline } from "ionicons/icons";  
import QRCode from "react-qr-code";

let inactiveTime: any;
let minutes = 10

function List(props: any) {
  const [city, _city] = useState<any>();
  const [list, _list] = useState<any>();
  const [items, _items] = useState<any>();
  const [selectedItem, _selectedItem] = useState<any>();



  const history = useHistory();
  const { id } = useParams<any>();

  function resetTimer(timer:any) {
    console.log("timereset")
    clearTimeout(timer);
    inactiveTime = setTimeout(() => {
      history.goBack();
    }, minutes * 60 * 1000);
  }

  useEffect(() => {

    Db.City().then((data: any) => {
      _city(data);
    });

    Db.ScreenLayout().then((data: any) => {
      minutes = data.ListTimeout
      resetTimer(inactiveTime)
    });



    Db.Category(id).then((data: any) => {
      console.log("list", data);
      let listThings = [];

      for (let e of data.linkedEvents) {
        if (moment() <= moment(e.endDate)) {
          e.type = "events"
          listThings.push(e);
        }
      }
      for (let p of data.linkedPlaces) {
        p.type = "places"
        listThings.push(p);
      }
      for (let p of data.linkedPosts) {
        p.type = "posts"
        listThings.push(p);
      }

      _list(data);
      _items(_.orderBy(_.orderBy(listThings, "name"), "startDate"));
      let firstItem = _.orderBy(_.orderBy(listThings, "name"), "startDate")[0];
      if(firstItem.heroImage && firstItem.heroImage.formats && firstItem.heroImage.formats.medium){
        firstItem.heroImageSelected =  firstItem.heroImage.formats.medium.url
      }else{
        firstItem.heroImageSelected =  firstItem.heroImage.url
      }
      _selectedItem(firstItem);
      console.log("listThings", listThings);
    });
  }, []);



  function List() {
    let index = 0
    return items.map((item: any) => {
      index++
      let heroImage = item.heroImage?.url
      if(item.heroImage && item.heroImage.formats && item.heroImage.formats.small){
        heroImage =  item.heroImage.formats.small.url
      }


      return (
        <div
          onClick={() => {

         
              let meta = {itemId:item.id, itemName:item.name, itemType: item.type};
                Db.Track("action","Item",meta)
            
            if(item.heroImage && item.heroImage.formats && item.heroImage.formats.medium){
              item.heroImageSelected = item.heroImage.formats.medium.url
            }else{
              item.heroImageSelected = item.heroImage.url
            }
            _selectedItem(item);
          }}
          key={index}
          className="List_items_item_box"
          style={{ backgroundImage: heroImage ? ("url(" + heroImage + ")") : "" }}
        >
          <div className="List_items_item_title_box_gradient">
            <div className="List_items_item_title_box">
              <div className="List_items_item_title">{item.name}</div>
              <div className="List_items_item_dates">
                {item.startDate ? moment(item.startDate).format("l") : null}
                {item.endDate ? " - " + moment(item.endDate).format("l") : null}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  if (list && list.name) {
    return (
      <IonPage>
        <IonHeader></IonHeader>
        <IonContent id="list" fullscreen>
          <div onMouseMove={()=>{
            resetTimer(inactiveTime)
          }} 
          
          onMouseDown={()=>{
            resetTimer(inactiveTime)
          }}
          style={{  height: "100vh" }}>
            <IonGrid>
              <IonRow>
                <IonCol size="4">
                  <div className="List_item_details_box">
                    {selectedItem ? (
                      <>
                        <div className="List_item_details_title">
                          {selectedItem.name}
                        </div>
                        {selectedItem.startDate ? (
                          <div className="List_item_details_dates">
                            <div className="List_item_details_dates_box">
                              <div className="List_item_details_dates_title">
                                from
                              </div>
                              <div className="List_item_details_dates_date">
                                {moment(selectedItem.startDate).format("l")}
                              </div>
                              <div className="List_item_details_dates_title">

                                {selectedItem.hideTimes != true ? (
                                   moment(selectedItem.startDate).format(
                                    "h:mm:ss a"
                                  )
                                  )
                                 : null}
                              </div>
                            </div>
                            <div className="List_item_details_dates_box">
                              <div className="List_item_details_dates_title">
                                to
                              </div>
                              <div className="List_item_details_dates_date">
                                {moment(selectedItem.endDate).format("l")}
                              </div>
                              <div className="List_item_details_dates_title">
                                {selectedItem.hideTimes != true ? ( moment(selectedItem.endDate).format(
                                  "h:mm:ss a"
                                )):null}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="List_item_details_address">
                          <IonRow>
                            <IonCol>
                          {selectedItem?.address1}
                          <br />
                          {selectedItem.address2 ? (
                            <>
                              {selectedItem.address2}
                              <br />
                            </>
                          ) : null}
                          {selectedItem.city ? (
                            <>{selectedItem.city}, </>
                          ) : null}
                          {selectedItem.state ? (
                            <>{selectedItem.state} </>
                          ) : null}
                          {selectedItem.zip ? <>{selectedItem.zip} </> : null}
                            </IonCol>
                            <IonCol style={{textAlign:"right"}}>
                              {selectedItem.website ? (
                            <QRCode value={selectedItem?.website} size={100}/>
                            ):null}
                            </IonCol>
                          </IonRow>
                        
                        </div>
                        <div className="List_item_details_text">
                          {selectedItem?.description}
                        </div>
                      </>
                    ) : null}
                  </div>
                </IonCol>
                <IonCol size="8">
                  <div
                    className="List_item_image_box"
                    style={{ backgroundImage: selectedItem?.heroImageSelected ? ( "url(" + selectedItem?.heroImageSelected + ")" ):""}}
                  >
                  </div>
                </IonCol>
              </IonRow>
              <IonRow >
                <div  className="List_items_box" >
                  <div className="List_items_title"> {list.name}</div>
                  <div>{items ? <List /> : null}</div>
                </div>
              </IonRow>
            </IonGrid>
          </div>
          <div  className="List_backButton">
            <IonIcon
         
              onClick={() => {
                history.goBack();
              }}
              icon={arrowBackCircleOutline}
            />
          </div>
        </IonContent>
      </IonPage>
    );
  } else {
    return (
      <IonPage>
      <IonHeader></IonHeader>
      <IonContent id="list" fullscreen>
        <h1 style={{textAlign:"center",color:"white"}}>Loading...</h1>
        </IonContent>
        </IonPage>
    );
  }
}

export default List;
