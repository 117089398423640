import "./ExploreContainer.css";

import {
  IonButton,
  IonContent,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import Db from "../services/db";
import { v4 as uuidv4 } from 'uuid';

interface ContainerProps {
  data: any;
}

function PulseForm(props: ContainerProps) {
  const [response, _response] = useState<any>();
  const [firstResponse, _firstResponse] = useState<any>();
  const [sessionId, _sessionId] = useState<any>();
  const [codeData, _codeData] = useState<any>();
  const [deviceId, _deviceId] = useState<any>();
  const [questions, _questions] = useState<any>();
  const [deviceData, _deviceData] = useState<any>();

  const FormSlides = useRef<any>();
  const input = useRef<any>();

  useEffect(() => {
    console.log("Device", window.navigator);

    _questions(props.data.question)

    _deviceData({
      userAgent:window.navigator.userAgent,
      cookieEnabled:window.navigator.cookieEnabled,
      language:window.navigator.language,
      platform:window.navigator.platform
    })

    console.log("props data", props.data);

    let propsSessionId = props.data.id;
    if (props.data.codeId) {
      _sessionId(props.data.codeId);
      propsSessionId = props.data.codeId;
    } else {
      _sessionId(props.data.id);
    }

    // let localLastCode = localStorage.getItem("lastCode");
    // if(localLastCode){
    //   let lastCode = JSON.parse(localLastCode);
    //   if(lastCode && lastCode.code == props.data.codeId){
    //     _codeData(lastCode);
    //   }
    // }

    for (let c of props.data.codes) {
      if (c.code == props.data.codeId) {
        _codeData(c);
      }
    }

    console.log("response-" + propsSessionId);
 
  let localSurveyResponse = localStorage.getItem("response-" + props.data.id);
  console.log("SurveyStoredResponseJSON", localSurveyResponse);

  if(localStorage.getItem("deviceId")){
    _deviceId(localStorage.getItem("deviceId"))
  }else{
    let deviceUUID = uuidv4();
    _deviceId(deviceUUID);
    localStorage.setItem("deviceId", deviceUUID)
  }

    if (localSurveyResponse) {
      let localSurveyResponseData = JSON.parse(localSurveyResponse);
      if(localSurveyResponseData){
          _firstResponse(localSurveyResponseData);
      }
    
      console.log("SurveyStoredResponse", localSurveyResponseData);
    }
    setTimeout(()=>{
    let localResponse = localStorage.getItem("response-" + propsSessionId);
    if (localResponse) {
      let localResponseData = JSON.parse(localResponse);

      _response(localResponseData);
      console.log("StoredResponse", localResponseData);
    }
   },1000)
   
  }, []);

  async function nextSlide() {
    if (FormSlides && FormSlides.current) {
      const swiper = await FormSlides.current.getSwiper();
      swiper.slideNext();
    }
  }

  async function firstSlide() {
    if (FormSlides && FormSlides.current) {
      const swiper = await FormSlides.current.getSwiper();
      swiper.slideTo(0);
    }
  }

  function SubmitAnswer(surveySession: any, newResponse: any) {
    if (surveySession.responseId) {
      let newNewResponse = [];

      if (firstResponse && firstResponse[0]) {
        for (let q of props.data.question) {
          if (q.answerOnce == true) {
            for (let f of firstResponse) {
              if (f.questionId == q.id ) {
                newNewResponse.push(f);
              }
            }
          }
        }
      }

      for (let n of newResponse) {
        newNewResponse.push(n);
      }

      let send = {
        id: surveySession.responseId,
        values: newNewResponse,
        survey: props.data.id,
        code: codeData?.id,
        deviceId: deviceId,
        deviceMeta:deviceData
      };

      localStorage.setItem(
        "response-" + props.data.id,
        JSON.stringify(newNewResponse)
      );

      Db.UpdateResponse(send).then((rData) => {
        console.log("rData", rData);
      });
    } else {
      let newNewResponse = [];

      if (firstResponse && firstResponse[0]) {
        for (let q of props.data.question) {
          if (q.answerOnce == true) {
            for (let f of firstResponse) {
              if (f.questionId == q.id ) {
                newNewResponse.push(f);
              }
            }
          }
        }
      }

      for (let n of newResponse) {
        newNewResponse.push(n);
      }
      localStorage.setItem(
        "response-" + props.data.id,
        JSON.stringify(newNewResponse)
      );

      let send = {
        values: newNewResponse,
        survey: props.data.id,
        code: codeData?.id,
        deviceId: deviceId,
        deviceMeta:deviceData
      };
      Db.CreateResponse(send).then((rData) => {
        console.log("rData", rData);
        surveySession.responseId = rData.id;
        let newSurveySession = {
          surveyId: props.data.id,
          responseId: rData.id,
          code: codeData?.id,
        };
        localStorage.setItem(
          "surveySession-" + sessionId,
          JSON.stringify(newSurveySession)
        );
      });
    }
  }

  function SaveAnswer(questionId: number, value: any) {
    console.log("response", response);
    let surveySession: any;
    let localSurveySession = localStorage.getItem("surveySession-" + sessionId);
    if (localSurveySession) {
      surveySession = JSON.parse(localSurveySession);
    } else {
      surveySession = {
        surveyId: props.data.id,
        code: codeData?.id,
      };
    }

    if (response && response[0]) {
      let newResponse = [];
      let found = false;
      for (let r of response) {
        if (r.questionId == questionId) {
          r.value = value;
          found = true;
        }
        newResponse.push(r);
      }

      if (found == false) {
        newResponse.push({
          questionId: questionId.toString(),
          value,
        });
      }
      localStorage.setItem(
        "response-" + sessionId,
        JSON.stringify(newResponse)
      );

      _response(newResponse);

      SubmitAnswer(surveySession, newResponse);
      console.log("newResponse", newResponse);
    } else {
      let newResponse = [
        {
          questionId: questionId.toString(),
          value,
        },
      ];
      localStorage.setItem(
        "response-" + sessionId,
        JSON.stringify(newResponse)
      );

      _response(newResponse);
      console.log("newResponse", newResponse);

      SubmitAnswer(surveySession, newResponse);
    }
  }

  function LastSlide() {
    return (
      <IonSlide key={99999}>
        <div style={{ height: "100vh" }}>
          <div
            style={{
              textAlign: "center",
              marginTop: 30,
              alignContent: "center",
              padding: "10%",
              height: 100,
              overflow: "hidden",
              width: "100%",
            }}
          ></div>
          <div
            style={{
              margin: "10px 40px 10px 40px",
              fontSize: 38,
              fontWeight: 700,
            }}
          >
            Success!!
          </div>
          <div
            style={{
              margin: "0px 40px 10px 40px",
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            Thank you for completing the <br />
            <br />
            {props.data.name}
          </div>
          <div
            style={{
              margin: "50px 40px 10px 40px",
              fontSize: 16,
              fontWeight: 300,
            }}
          >
            You may now close this window
            <br />
            <br />
            or
            <br />
            <br />
            <IonButton
              onClick={() => {
                localStorage.removeItem("response-" + sessionId);
                localStorage.removeItem("surveySession-" + sessionId);
                _response(null);
                firstSlide();
              }}
              shape="round"
              size="small"
              color="dark"
              expand="block"
            >
              Submit Another
            </IonButton>
          </div>
        </div>
      </IonSlide>
    );
  }

  interface SlideOptions {
    image: any;
    text: string;
    subtext: string;
    type: string;
    data: any;
    id: number;
    response?: any;
    answerOnce:any;
  }

  function Slide(options: SlideOptions) {
    let qResponse: any;

    if (response && response[0]) {
      for (let r of response) {
        if (r.questionId == options.id) {
          console.log("FOUND", r);
                  qResponse = r;
    
        }
      }
    }
    if (firstResponse && firstResponse[0]) {
      for (let r of firstResponse) {
        if (r.questionId == options.id && options.answerOnce == true) {
          console.log("FOUNDfirst", r);
                  qResponse = r;
        }
      }
    }

    return (
      <div style={{ minHeight: "100vh" }}>
        <div
          style={{
            textAlign: "center",
            marginTop: 30,
            alignContent: "center",
            padding: "10%",
            maxHeight: 400,
            overflow: "hidden",
          }}
        >
          <img
            src={
              options?.image &&
              options?.image.formats &&
              options?.image.formats.small
                ? options?.image.formats.small.url
                : options?.image.url
            }
            style={{ maxHeight: 380, borderRadius: 10 }}
          />
        </div>
        <div
          style={{
            margin: "10px 40px 10px 40px",
            fontSize: 28,
            fontWeight: 700,
          }}
        >
          {options.text}
        </div>
        <div
          style={{
            margin: "0px 40px 10px 40px",
            fontSize: 16,
            fontWeight: 300,
          }}
        >
          {options.subtext}
        </div>

        {options.type == "select"
          ? SlideOptionsOther(options, qResponse)
          : null}
        {options.type == "input" ? SlideInput(options, qResponse) : null}
        {options.type == "buttons" ? SlideButtons(options, qResponse) : null}
      </div>
    );
  }

  function SlideOptionsOther(question: any, qResponse: any) {
    const [selectValue, _selectValue] = useState<any>(null);
    const [value, _value] = useState<any>();

    return (
      <>
        <div
          style={{
            fontSize: 20,
            width: "100%",
            marginTop: 20,
            fontWeight: 900,
            textAlign: "center",
            borderRadius: 40,
          }}
        >
          {/* <input type="text" style={{borderRadius:30,padding:15, width:"calc(100% - 60px)",margin:6,textAlign:"center"}}/> */}
          <IonSelect
            interface="action-sheet"
            onIonChange={(e) => {
              console.log("qDate", question);
              SaveAnswer(question.id, e.detail.value);
              _selectValue(e.detail.value);
              console.log(e.detail.value);
              if (e.detail.value != "other") {
                nextSlide();
              }
            }}
            style={{
              fontSize: 20,
              borderRadius: 30,
              backgroundColor: "white",
              margin: 30,
            }}
            placeholder="Select one"
            value={qResponse?.value}
          >
            {question.data.map((item: any, index: number) => {
              return (
                <IonSelectOption
                  style={{ fontSize: 20 }}
                  key={index}
                  value={item.value}
                >
                  {item.text}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </div>
        {qResponse && qResponse.value == "other" ? (
          <div
            style={{
              fontSize: 20,
              marginTop: 20,
              fontWeight: 900,
              textAlign: "center",
              borderRadius: 40,
            }}
          >
            <input
              type="text"
              placeholder="Enter answer"
              onChange={(e) => {
                _value(e.currentTarget.value);
              }}
              style={{
                borderRadius: 30,
                padding: 10,
                border: "0px solid #eee",
                width: "calc(100% - 60px)",
                margin: 6,
                textAlign: "center",
              }}
            />
            {value ? (
              <div style={{ padding: 30 }}>
                <IonButton
                  onClick={() => {
                    SaveAnswer(question.id, value);
                    nextSlide();
                  }}
                  color="dark"
                  shape="round"
                  expand="block"
                >
                  Next
                </IonButton>
              </div>
            ) : null}
          </div>
        ) : null}
        {qResponse && qResponse.value != "other" ? (
          <div style={{ padding: 30 }}>
            <IonButton
              onClick={() => {
                nextSlide();
              }}
              color="dark"
              shape="round"
              expand="block"
            >
              Next
            </IonButton>
          </div>
        ) : (
          <>
            {question && question.required != true ? (
              <div style={{ padding: 30 }}>
                <IonButton
                  onClick={() => {
                    nextSlide();
                  }}
                  color="light"
                  shape="round"
                  expand="block"
                  size="small"
                >
                  Skip
                </IonButton>
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }

  function SlideInput(question: any, qResponse: any) {
    const [qValue, _qValue] = useState<string>(qResponse?.value);

    return (
      <>
        <div
          style={{
            fontSize: 20,
            width: "100%",
            marginTop: 20,
            fontWeight: 900,
            textAlign: "center",
            borderRadius: 40,
          }}
        >
          <input
            placeholder="Enter answer"
            type="text"
            onChange={(e) => {
              _qValue(e.currentTarget.value);
            }}
            value={qValue}
            style={{
              borderRadius: 30,
              padding: 10,
              border: "0px solid #eee",
              width: "calc(100% - 60px)",
              margin: 6,
              textAlign: "center",
            }}
          />
        </div>
        <div
          style={{
            fontSize: 60,
            margin: "60px 30px 30px 30px",
            fontWeight: 900,
            textAlign: "center",
          }}
        >
          {qValue ? (
            <IonButton
              onClick={() => {
                nextSlide();
                SaveAnswer(question.id, qValue);
              }}
              color="dark"
              shape="round"
              expand="block"
            >
              Next
            </IonButton>
          ) : (
            <>
              {question && question.required != true ? (
                <IonButton
                  onClick={() => {
                    nextSlide();
                  }}
                  color="light"
                  size="small"
                  shape="round"
                  expand="block"
                >
                  Skip
                </IonButton>
              ) : null}
            </>
          )}
        </div>
      </>
    );
  }

  function SlideButtons(question: any, qResponse: any) {
    return (
      <>
        {question.data.map((item: any, index: number) => {
          return (
            <div
              key={index}
              style={{
                width: "100%",
                marginTop: 20,
                paddingLeft: 30,
                paddingRight: 30,
                fontWeight: 900,
                textAlign: "center",
                borderRadius: 40,
              }}
            >
              <IonButton
                onClick={() => {
                  SaveAnswer(question.id, item.value);
                  nextSlide();
                }}
                color={
                  qResponse && qResponse.value == item.value
                    ? "secondary"
                    : "dark"
                }
                shape="round"
                expand="block"
              >
                {item.text}
              </IonButton>
            </div>
          );
        })}

        {question && question.required != true ? (
          <div
            key={9999}
            style={{
              width: "100%",
              marginTop: 60,
              paddingLeft: 30,
              paddingRight: 30,
              fontWeight: 900,
              textAlign: "center",
              borderRadius: 40,
            }}
          >
            <IonButton
              onClick={() => {
                nextSlide();
              }}
              color="light"
              size="small"
              shape="round"
              expand="block"
            >
              Skip
            </IonButton>
          </div>
        ) : null}
      </>
    );
  }

  function List() {
    if(questions){return questions.map((s: any, index: number) => {

        return (
          <IonSlide key={index}>
            <Slide
              id={s.id}
              image={s.image}
              text={s.text}
              subtext={s.subtext}
              type={s.type}
              data={s.items}
              answerOnce={s.answerOnce}
            />
          </IonSlide>
        );
    
    });}else{
      return null
    }
    
  }

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    loop: false,
    parallax: true,
  };

  return (
    <IonContent>
      <IonSlides
        ref={FormSlides}
        style={{ boxShadow: "0px 90px 200px 17px rgba(0,0,0,0.20) inset" }}
        pager={false}
        options={slideOpts}
      >
        {List()}
        {LastSlide()}
      </IonSlides>
      <div
        style={{
          textAlign: "center",
          position: "fixed",
          bottom: 30,
          left: 0,
          width: "100%",
        }}
      >
        <div className="bubble current"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
      </div>
    </IonContent>
  );
}

export default PulseForm;
