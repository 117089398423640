import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRoute,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    useIonViewDidEnter,
  } from "@ionic/react";
  import "../CmsHome.css";
  import { add, close, pin, pinOutline, playCircleSharp } from "ionicons/icons";
  import { useEffect, useState } from "react";
  import GoogleMapReact from 'google-map-react';
  import Db from "../../services/db";
  
  interface Props {
    selected: (data:any)=>any
    searchString: string;
  }
  
  
  
  export default function ImageSearch(props: Props) {
    const [imageSearchText, _imageSearchText] = useState<any>();
    // showImageSearchResults
    const [showImageSearchResults, _showImageSearchResults] =
      useState<boolean>(false);
    // imageSearchResults
  const [imageSearch, _imageSearch] = useState<any>();


      useEffect(() => {

        setTimeout(() => {
            _imageSearchText(props.searchString);
          }, 2000);
      } , []);

      function searchImages() {
        if (imageSearchText) {
          _showImageSearchResults(true);
          Db.SearchImages(imageSearchText).then((data2: any) => {
            console.log("images", data2);
            _imageSearch(data2);
          });
        }
      }

      function ImageSearchList() {
        if (imageSearch) {
          return imageSearch.map((image: any, index: number) => {
            return (
              <div className="image-search-item" key={index}>
                <img
                  onClick={() => {
                    props.selected(image.thumbnailUrl);
                
                  }}
                  style={{ margin: 0, width: "100%" }}
                  src={image.thumbnailUrl}
                  alt={image.name}
                />
              </div>
            );
          });
        } else {
          return null;
        }
      }
    
   
    return (
        <>
        <div  style={{marginTop:20}}>
                              
                             <IonLabel style={{marginTop:20}}>
                                Search Online Images{" "}
                                {showImageSearchResults && (
                                  <span
                                    onClick={() => {
                                      _showImageSearchResults(false);
                                    }}
                                    style={{ color: "grey" }}
                                  >
                                    (hide)
                                  </span>
                                )}
                            </IonLabel>
                              <IonItem>
                                <IonInput
                                  type="text"
                                  placeholder="Search"
                                  value={imageSearchText}
                                  onIonChange={(e) => {
                                    _imageSearchText(e.detail.value);
                                  }}
                                />
                                <IonButton
                                  shape="round"
                                  onClick={() => {
                                    searchImages();
                                  }}
                                >
                                  Search
                                </IonButton>
                              </IonItem>
                              {showImageSearchResults && 
                              (
                              <div>
                                <div>
                                <IonButton shape="round" 
                                expand="block"
                                size="small"
                                color="light"
                                onClick={() => {
                                    _showImageSearchResults(false);
                                }}
                                >
                                   Close Image Search Box
                                </IonButton>
                              </div>
                              <ImageSearchList />
                              <div>
                                <IonButton shape="round" 
                                expand="block"
                                size="small"
                                color="light"
                                onClick={() => {
                                    _showImageSearchResults(false);
                                }}
                                >
                                   Close Image Search Box
                                </IonButton>
                              </div>
                              </div>
                              )}
                            </div>
        
        </>
    );
  }
  