import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import Submit from './pages/Submit';
import Screen from './pages/Screen';
import Error404 from './pages/Error404';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/locomotive-scroll.css';
import List from './pages/List';
import ListTv from './pages/ListTv';
import Tv from './pages/Tv';
import ListTvPortrait from './pages/ListTvPortrait';
import Embed from './pages/Embed';
import RedeemAuth from './redeem/Auth';
import Download from './pages/Download';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import CmsHome from './cms/Home';
import SiteHome from './site/SiteHome';
import QRCode from 'react-qr-code';
import QrCodePage from './pages/QrCodePage';
import QrCodeScan from './pages/QrCodeScan';
import TvApp from './pages/TvApp';
import Survey from './pages/Survey';
import CalendarView from './pages/CalendarView';
import ListView from './pages/ListView';




const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/app/home">
          <Home />
        </Route>
        <Route exact path="/app/submit">
          <Submit />
        </Route>
        <Route exact path="/app/screen">
          <Screen />
        </Route>
        <Route exact path="/app/list/:id">
          <List />
        </Route>
        <Route exact path="/app/listtv/:id">
          <ListTv />
        </Route>
        <Route exact path="/app/listtvportrait/:id">
          <ListTvPortrait />
        </Route>
        <Route exact path="/app/tv">
          <Tv />
        </Route>
        <Route exact path="/app/tvapp">
          <TvApp />
        </Route>
        <Route exact path="/app/embed">
          <Embed />
        </Route>
        <Route exact path="/app/download">
          <Download />
        </Route>
        <Route exact path="/app/privacypolicy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/app/terms">
          <Terms />
        </Route>
        <Route exact path="/site">
          <SiteHome />
        </Route>
        <Route exact path="/code/:id">
          <QrCodePage />
        </Route>
        <Route exact path="/c/:id">
          <QrCodeScan />
        </Route>
        <Route exact path="/app/survey/:id">
          <Survey />
        </Route>
        <Route exact path="/app/survey/:id">
          <Survey />
        </Route>
        <Route exact path="/app/view/calendar/:id">
          <CalendarView />
        </Route>
        <Route exact path="/app/view/list/:id">
          <ListView />
        </Route> 
        <Route exact path="/redeem/auth">
          <RedeemAuth />
        </Route>
        <Route exact path="/">
          <Redirect to="/app/home" />
        </Route>
        <Route>
          <Error404 />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
