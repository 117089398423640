import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import Db from "../services/db";

function TvApp() {
  const [city, _city] = useState<any>();
  const [list, _list] = useState<any>();
  const [destination, _destination] = useState<any>();

  const { search } = useLocation<any>();
  const params = new URLSearchParams(search);
  const destinationId = params.get("destination"); // bar

  useEffect(() => {
  
    Db.City().then((data: any) => {
      //console.log(data)
      _city(data);

     
      if(localStorage.getItem("selectedList")){
        window.location.href = "/app/listtv/" + localStorage.getItem("selectedList")
      }
      Db.Destinations().then((data: any) => {
        console.log("_layout", data);
        localStorage.setItem("destinations", JSON.stringify(data));
        
        _list(data);
        
      });
    });
  }, []);

  function Lists() {
    if (list) {
      return list.filter((data:any)=>{
        return data.redirect != true
      }).map((item: any) => {
        return (
          <div
            className="Tv_imageCard"
            style={{ backgroundImage: "url(" + item.heroImage+ ")" }}
          >
            <div className="Tv_imageCardContent">
              <IonButton
                style={{ flex: 1, width:"100%", overflow:"hidden" }}
                expand="full"
                shape="round"
                color="light"
                
                onClick={()=>{
                  localStorage.setItem("destination", JSON.stringify(item));
                    window.location.href = "/app/tv?destination=" + item.id
                }}
                onKeyDown={(e)=>{
                  if(e.keyCode == 13 || e.key == "Enter"){
                    localStorage.setItem("destination", JSON.stringify(item));
                    window.location.href = "/app/tv?destination=" + item.id
                  }
                 
                }}
              >
                {item.name}
              </IonButton>
            </div>

            {/* <IonCol size='3'>
                        <IonButton expand="full" shape="round" color="dark" href={"/app/listtvportrait/"+list.id}>{list.name}</IonButton>
                    </IonCol> */}
          </div>
        );
      });
    } else {
      return (
        <h3 style={{ textAlign: "center", paddingTop: 30 }}>Loading ...</h3>
      );
    }
  }

  return (
    <IonPage>
      <IonContent>
        <div style={{ backgroundColor: "#000", minHeight: "100vh" }}>
          {/* <IonGrid style={{textAlign:"center",paddingTop:30}}>
            <IonRow>
                <IonCol offset='3' size='3'  style={{width:400,  fontSize:25}} >Landscape</IonCol>
                <IonCol size='3'   style={{width:400,  fontSize:25}}>Portrait</IonCol>
            </IonRow>
        </IonGrid> */}
          <IonGrid style={{ textAlign: "center", paddingTop: 10 }}>
            <h1
              style={{
                textAlign: "left",
                padding: "0px 0 0 50px",
                color: "#ccc",
              }}
            > 
            </h1>
            <Lists />
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default TvApp;
