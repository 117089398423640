import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { useEffect, useState } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import Db from '../services/db';
import List from './List';
import './Screen.css';
import {  FaHandPointUp } from 'react-icons/fa';
import { refreshCircleOutline } from 'ionicons/icons';

function Screen(){
  const [city,_city] = useState<any>();
  const [layout,_layout] = useState<any>();
  const [lists,_lists] = useState<any>();

  useIonViewDidEnter(()=>{
    loadData()
  })

  function loadData(){
    
    Db.City().then((data:any)=>{
      //console.log(data)
      localStorage.setItem("city",JSON.stringify(data))
      _city(data)
    
    })
    Db.ScreenLayout().then((data:any)=>{
      console.log("_layout",data)
      localStorage.setItem("layout",JSON.stringify(data))
      _layout(data)
    })
    Db.Categories().then((data:any)=>{
      console.log("_layout",data)
      localStorage.setItem("lists",JSON.stringify(data))
      _lists(data)
    })
  }

  function Lists(){
  return  lists.filter((x:any)=>x.showHome == true).map((list:any)=>{
 return  (
      <a onClick={()=>{
        let meta = {id:list.id, name:list.name};
          Db.Track("action","List",meta).then(data=>{
            console.log("track",data)
          })  
          
      }}  
       href={"/app/list/"+ list.id}
      >
      <div className="cardContainer" style={{backgroundImage:list.image ? "url("+list.image.formats.small.url+")" : ""}}> 
      <div className="cardTitle">
        <div className="cardDetails" >           
          <div className="cardName">{list.name}</div>
          </div>
      </div>
  </div>
  </a>)
    })
   
    
  }

  function TextLines(){

      return layout.Line.map((line:any)=>{
        return(
          <div className="heroTitle" style={{fontSize: line.size/10+"vw", color: line.color ? line.color: "white"}}>{line.Text}</div>
        )
      })
    
  }


  return (
    <IonPage>
      <IonHeader>
      
      </IonHeader>
      <IonContent id="screen" fullscreen scrollY={false}>
        {layout && layout.ShowTouchScreenIndicator == true ? (
        <div className="Screen_exploreBox">
        <div style={{fontWeight:900}}>
          Touch Screen
        </div>
         
       <FaHandPointUp  style={{fontSize:"5vh",paddingTop:5}} />
        </div>
        ): null}
        <div className="page"  style={{backgroundImage:layout && layout.BackgroundImage && layout.BackgroundVideo == null ? "url("+layout.BackgroundImage.url+")" : ""}}>
          {layout && layout.BackgroundVideo ?(
        <video width="100%" autoPlay muted loop id="myVideo">
          <source src={"https://"+layout.BackgroundVideo.url} type="video/mp4" />
        </video>
          ):null}
       
        <IonGrid style={{ }}>
        <IonRow className="tileRow" style={{height:"70vh"}}>
          <IonCol style={{padding:40}}>
            {layout && layout.Line ? (
          <TextLines />
          ): null}
          </IonCol>
        </IonRow>
        <IonRow className="tileRow" style={{height:"calc(30vh - 35px)", padding:"28px 10px 20px 10px"}}>
          {lists ? <Lists />: null}
        </IonRow>
        </IonGrid>          
        </div>
        <div style={{position:"fixed", top:20,right:20, fontSize:"3vh", color:"#00000040"}}>
          <IonIcon onClick={()=>{
            window.location.reload();
          }} icon={refreshCircleOutline} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Screen;
