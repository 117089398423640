import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import Db from "../services/db";
import "./Home.css";
import appleicon from "../assets/img/appleicon.png";
import androidicon from "../assets/img/androidicon.png";
import { QRCode } from "react-qrcode-logo";

function QrCodePage() {
  const [city, _city] = useState<any>();
  const { id } = useParams<any>();

  useEffect(() => {
    Db.City().then((data: any) => {
      //console.log(data)
      _city(data);
    });
    if (id) {
      Db.Code(id).then((code) => {
        console.log(code);
      });
    }
  }, []);
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h4>
          CODE: <b>{id}</b>{" "}
        </h4>
        <div>
          <QRCode
            ecLevel="Q"
            value={Db.host + "c/" + id}
            eyeRadius={5} // 5 pixel radius for all corners of all positional eyes
            qrStyle="dots"
            logoImage={city?.logo.url}
            removeQrCodeBehindLogo={true}
            logoWidth={30}
            size={300}
          />
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          width: "100%",
          marginBottom: -10,
          marginTop: 10,
        }}
      >
        DOWNLOAD APP
      </div>
      <div>
        <br />
        <div style={{ textAlign: "center" }}>
          <a
            href={city?.appStore?.google}
            style={{ color: "black", textDecoration: "none" }}
          >
            <div
              style={{
                textAlign: "center",
                display: "inline-block",
                margin: "10px 50px 0px 50px",
              }}
              className="col"
            >
              <img style={{ width: 50, borderRadius: 5 }} src={androidicon} />
              <br />
              Android
              <br />
            </div>
          </a>
          <a
            href={city?.appStore?.apple}
            style={{ color: "black", textDecoration: "none" }}
          ></a>
          <div
            style={{
              textAlign: "center",
              display: "inline-block",
              margin: "10px 50px 0px 50px",
            }}
            className="col"
          >
            <img style={{ width: 44, borderRadius: 5 }} src={appleicon} />
            <br />
            iPhone
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default QrCodePage;
