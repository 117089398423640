import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Db from "../services/db";
import "./Home.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useLocation, useParams } from "react-router";
import { arrowBack, calendar, close, download } from "ionicons/icons";
import { isMobile } from "react-device-detect";

const localizer = momentLocalizer(moment);

function ListView() {
  const [city, _city] = useState<any>();
  const [list, _list] = useState<any>();
  const [selectedItem, _selectedItem] = useState<any>();
  const [modal, _modal] = useState<boolean>(false);
  const { id } = useParams<any>();

  const { search } = useLocation<any>();
  const params = new URLSearchParams(search);
  const destinationId = params.get("destination");
  const showButtons = params.get("buttons");

  useEffect(() => {
    Db.City().then((data: any) => {
      //console.log(data)
      _city(data);
    });

    Db.CategoryFlat(id).then((data: any) => {
      console.log("list", data);
      _list(data);
    });
  }, []);

  function Event(data: any) {
    console.log("event", data);

    return (
      <div
        className="eventCard"
        onClick={() => {
          _selectedItem(data.item);
          _modal(true);
        }}
      >
        <div className="eventText">
          <div className="eventTitle">{data.item.name}</div>
        </div>
        <div className="eventDates">
          <div style={{ fontSize: 12, fontWeight: 500 }}>
            {moment(data.item.startDate).format("MMM")}
          </div>
          <div style={{ fontSize: 30, fontWeight: 800 }}>
            {moment(data.item.startDate).format("D")}
          </div>
        </div>
        <div
          style={{ backgroundImage: `url(${data.item.heroImage?.url})` }}
          className="Feed_Item_image"
        ></div>
      </div>
    );
  }

  function ItemsList() {
    if (list) {
      return list
        .filter((item: any) => {
          return (
            moment(item?.startDate).diff(moment(), "days") >= 0 &&
            item.heroImage
          );
        })
        .map((item: any, index: number) => {
          return (
            <div key={index}>
              <Event item={item} />
            </div>
          );
        });
    } else {
      return null;
    }
  }

  return (
    <IonPage>
      <IonContent>{ItemsList()}</IonContent>
      {showButtons != "false" && (
      <IonFooter>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton
                shape="round"
                href={"/app/submit?destination=" + destinationId}
                size="large"
                color="light"
                expand="block"
              >
                <IonIcon icon={calendar}></IonIcon>
                &nbsp; Submit Something
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                shape="round"
                href="/app/download"
                size="large"
                color="secondary"
                expand="block"
              >
                <IonIcon icon={download}></IonIcon>
                &nbsp; Download The App
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
      )}
      <IonModal
        isOpen={modal}
        onDidDismiss={() => {
          _modal(false);
        }}
      >
        <IonContent>
          <div
            style={{
              width: "100%",
              backgroundColor: "#ccc",
              borderRadius: "0px 0px 30px 30px",
              height: 400,
              backgroundImage: "url(" + selectedItem?.heroImage.url + ")",
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div style={{ padding: 20 }}>
            <h2>{selectedItem?.name}</h2>
            <b>
              {selectedItem &&
                selectedItem.startDate &&
                moment(selectedItem.startDate).format("lll")}{" "}
              -{" "}
              {selectedItem &&
                selectedItem.endDate &&
                moment(selectedItem.endDate).format("lll")}
            </b>
            <p>{selectedItem?.description}</p>
          </div>
        </IonContent>
        <IonFooter>
          <IonGrid>
            <IonRow>
              <IonCol>
                {selectedItem?.website && (
                  <IonButton
                    color="secondary"
                    shape="round"
                    href={selectedItem?.website}
                    target="_blank"
                  >
                    Visit Website
                  </IonButton>
                )}
              </IonCol>
              <IonCol style={{ textAlign: "right" }}>
                <IonButton
                  shape="round"
                  color="dark"
                  fill="clear"
                  onClick={() => {
                    _modal(false);
                  }}
                >
                  <IonIcon slot="start" icon={close} />
                  close
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonFooter>
      </IonModal>
    </IonPage>
  );
}

export default ListView;
