import {
  IonButton,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInput,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  useIonViewDidEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "./Submit.css";
import { useHistory, useLocation, useParams } from "react-router";

import Db from "../services/db";
import { arrowBackCircle } from "ionicons/icons";

function Submit() {
  const [city, _city] = useState<any>();
  const [description, _description] = useState<any>();
  const [address1, _address1] = useState<any>();
  const [address2, _address2] = useState<any>();
  const [cityField, _cityField] = useState<any>();
  const [type, _type] = useState<any>();
  const [state, _state] = useState<any>();
  const [zip, _zip] = useState<any>();
  const [phone, _phone] = useState<any>();
  const [website, _website] = useState<any>();
  const [EndDate, _EndDate] = useState<any>();
  const [StartDate, _StartDate] = useState<any>();
  const [name, _name] = useState<any>();
  const [heroImage, _heroImage] = useState<any>();
  const [submitted, _submitted] = useState(false);
  const [contactName, _contactName] = useState<any>();
  const [contactEmail, _contactEmail] = useState<any>();
  let history = useHistory();
  const {search} = useLocation<any>();
  const params = new URLSearchParams(search);
  const destinationId = params.get('destination'); // bar
  const listId = params.get('list'); // bar
  const [destination, _destination] = useState<any>();

  let urlParams: any = useParams();

  useEffect(() => {
    
    if(destinationId && destinationId !== "null"){
      Db.Destination(destinationId).then((data:any)=>{
        _destination(data.city)
        console.log("destination",data)
      })  
    }
    Db.City().then((data: any) => {
      _city(data);
    });
  }, []);

  return (
    <IonPage>
      {city && city.name ? (
        <IonContent>
          <div className="form">
            <h2
              style={{
                textAlign: "center",
                paddingBottom: 10,
                paddingTop: 40,
              }}
            >
              {destination && destination.name ? destination.name : city.name}
            </h2>
            <div style={{ position: "absolute", top: 54, left: 10 }}></div>

            {submitted != true ? (
              <>
                <h4 style={{ textAlign: "center" }}>Submit Something</h4>
                <div style={{ margin: 20 }}>
                  <div className="NewForm_input_container">
                    <b>Type *</b>
                    <select
                      style={{
                        border: "1px #ccc solid",
                        borderRadius: 5,
                        backgroundColor: "white",
                        color: "#000",
                        width: "100%",
                        padding: 10,
                      }}
                      onChange={(e) => {
                        _type(e.target.value);
                      }}
                      name="item-type"
                      id="type-select"
                    >
                      <option value="">--Please choose an option--</option>
                      <option value="events">Event</option>
                      <option value="places">Place</option>
                    </select>
                  </div>
                  {type ? (
                    <>
                      {type == "events" ? (
                        <>
                          <div className="NewForm_input_container">
                            <b>Start Date</b>
                            <div>
                              <input
                                style={{
                                  border: "1px #ccc solid",
                                  borderRadius: 5,
                                  backgroundColor: "white",
                                  color: "#000",
                                  width: "100%",
                                  padding: 10,
                                }}
                                onChange={async (e: any) => {
                                  
                                  console.log("date", e.target.value);
                                  _StartDate(e.target.value);
                                  // params.updateImage(e.target.files[0]);
                                }}
                                type="datetime-local"
                                id="start-time"
                                name="start-time"
                              />
                            </div>
                          </div>
                          <div className="NewForm_input_container">
                            <b>End Date</b>
                            <div>
                              <input
                                style={{
                                  border: "1px #ccc solid",
                                  borderRadius: 5,
                                  backgroundColor: "white",
                                  color: "#000",
                                  width: "100%",
                                  padding: 10,
                                }}
                                onChange={async (e: any) => {
                                  _EndDate(e.target.value);
                                  // params.updateImage(e.target.files[0]);
                                }}
                                type="datetime-local"
                                id="end-time"
                                name="end-time"
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="NewForm_input_container">
                        <b>Image</b>
                        <input
                          accept="image/*"
                          style={{
                            border: "1px #ccc solid",
                            padding: 5,
                            marginBottom: 10,
                            marginTop: 10,
                            width: "100%",
                            borderRadius: 5,
                            backgroundColor: "white",
                          }}
                          onChange={async (e: any) => {
                            if(e.target.files[0].size > 3145728){
                              alert("File is to large, the file must be less then 3MB!");
                              e.target.value = "";
                           }else{
                            if(e.target.files[0].type == "image/png" || 
                            e.target.files[0].type == "image/jpg" ||
                            e.target.files[0].type == "image/jpeg" 
                            ){
                              _heroImage(e.target.files[0]);
                            }else{
                              console.log("e", e.target.files[0])
                              alert("Wrong file type, must be JPG or PNG");
                              e.target.value = "";
                            }
                           };
                          
                            // params.updateImage(e.target.files[0]);
                          }}
                          type="file"
                        ></input>
                      </div>
                      <div className="NewForm_input_container">
                        <b>Title</b>
                        <IonInput
                          className="NewForm_input"
                          value={name}
                          placeholder="Enter Input"
                          onIonChange={(e) => _name(e.detail.value)}
                        ></IonInput>
                      </div>
                      <div className="NewForm_input_container">
                        <b>Address Line 1</b>
                        <IonInput
                          className="NewForm_input"
                          value={address1}
                          placeholder="Enter Input"
                          onIonChange={(e) => _address1(e.detail.value)}
                        ></IonInput>
                      </div>
                      <div className="NewForm_input_container">
                        <b>Address Line 2</b>
                        <IonInput
                          className="NewForm_input"
                          value={address2}
                          placeholder="Enter Input"
                          onIonChange={(e) => _address2(e.detail.value)}
                        ></IonInput>
                      </div>
                      <div className="NewForm_input_container">
                        <b>City</b>
                        <IonInput
                          className="NewForm_input"
                          value={cityField}
                          placeholder="Enter Input"
                          onIonChange={(e) => _cityField(e.detail.value)}
                        ></IonInput>
                      </div>
                      <div className="NewForm_input_container">
                        <b>State</b>
                        <IonInput
                          className="NewForm_input"
                          value={state}
                          placeholder="Enter Input"
                          onIonChange={(e) => _state(e.detail.value)}
                        ></IonInput>
                      </div>
                      <div className="NewForm_input_container">
                        <b>Zip</b>
                        <IonInput
                          className="NewForm_input"
                          value={zip}
                          placeholder="Enter Input"
                          onIonChange={(e) => _zip(e.detail.value)}
                        ></IonInput>
                      </div>
                      <div className="NewForm_input_container">
                        <b>Phone</b>
                        <IonInput
                          className="NewForm_input"
                          value={phone}
                          placeholder="Enter Input"
                          onIonChange={(e) => _phone(e.detail.value)}
                        ></IonInput>
                      </div>
                      <div className="NewForm_input_container">
                        <b>Website</b>
                        <IonInput
                          className="NewForm_input"
                          value={website}
                          placeholder="Enter Input"
                          onIonChange={(e) => _website(e.detail.value)}
                        ></IonInput>
                      </div>
                      <div className="NewForm_input_container">
                        <b>Description</b>
                        <IonTextarea
                          onIonChange={(e) => {
                            _description(e.detail.value!);
                          }}
                          style={{
                            backgroundColor: "#fff",
                            border: "1px #ccc solid",
                            paddingLeft: 5,
                            paddingRight: 5,
                            marginTop: 10,
                            marginBottom: 10,
                            borderRadius: 8,
                          }}
                          rows={16}
                          value={description}
                        ></IonTextarea>
                      </div>
                      <div className="NewForm_input_container">
                      <b>Contact Name</b>
                      <IonInput
                        className="NewForm_input"
                        value={contactName}
                        placeholder="Enter Input"
                        onIonChange={(e) => _contactName(e.detail.value)}
                      ></IonInput>
                    </div>
                    <div className="NewForm_input_container">
                      <b>Contact Email</b>
                      <IonInput
                        className="NewForm_input"
                        value={contactEmail}
                        placeholder="Enter Input"
                        onIonChange={(e) => _contactEmail(e.detail.value)}
                      ></IonInput>
                    </div>
                      <div style={{paddingTop:50}}>
                        {contactName && contactEmail ? (
                        <IonButton
                          color="secondary"
                          shape="round"
                          onClick={() => {
                            _submitted(true);
                            if (type == "events") {
                              let newEvent:any = {
                                name,
                                address1,
                                address2,
                                city: cityField,
                                state,
                                zip,
                                contactPhone:phone,
                                contactName:contactName,
                                contactEmail:contactEmail,
                                website,
                                shortDescription: description,
                                description: description,
                                dates: [
                                  {
                                    EndDate,
                                    StartDate,
                                  },
                                ],
                              };
                              
                              if(destinationId){
                                newEvent.destinations = [destinationId]
                              }

                              if(listId){
                                newEvent.categoryId = [listId]
                              }

                              Db.DraftEvent(newEvent).then(async (data) => {
                                console.log("Event Created", data);
                                if (heroImage) {
                                  await Db.Upload(
                                    heroImage,
                                    "events",
                                    "heroImage",
                                    data.id
                                  ).then((data) => {
                                    console.log("Upload Success");
                                  });
                                }
                              });
                            }
                            if (type == "places") {
                              let newPlace:any = {
                                name,
                                address1,
                                address2,
                                city: cityField,
                                state,
                                zip,
                                phone:phone,
                                contactPhone:phone,
                                contactName:contactName,
                                contactEmail:contactEmail,
                                website,
                                shortDescription: description,
                                description: description,
                                
                              };

                              if(destinationId){
                                newPlace.destinations = [destinationId]
                              }
                              
                              if(listId){
                                newPlace.categoryId = listId
                              }
                          

                              Db.DraftPlace(newPlace).then(async (data) => {
                                console.log("Place Created", data);
                                if (heroImage) {
                                  await Db.Upload(
                                    heroImage,
                                    "places",
                                    "heroImage",
                                    data.id
                                  ).then((data) => {
                                    console.log("Upload Success");
                                  });
                                }
                              });
                            }
                          }}
                          expand="block"
                        >
                          Submit
                        </IonButton>
                        ):(
                          <>
                          <div style={{textAlign:"center", color:"red", fontWeight:900}}>
                            Contact Name and Email are required!
                          </div>
                          <IonButton expand="block" color="secondary" shape="round" disabled={true}>
                            Submit
                          </IonButton>
                          </>
                        )}
                      </div>
                      {urlParams && urlParams.web == true ? null : (
                        <div style={{ paddingTop: 40, paddingBottom: 200 }}>
                          <IonButton
                            shape="round"
                            onClick={() => {
                              window.location.reload();
                            }}
                            color="warning"
                            size="small"
                            expand="block"
                          >
                            Reset Form
                          </IonButton>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <h4 style={{ textAlign: "center" }}>Thank You </h4>
                <div
                  style={{
                    padding: 20,
                    paddingBottom: 10,
                    textAlign: "center",
                  }}
                >
                  {name} has been submitted and is now pending approval
                </div>
                <div style={{ padding: 20, paddingBottom: 200 }}>
                  <IonButton
                    shape="round"
                    onClick={() => {
                      window.location.reload();
                    }}
                    color="success"
                    size="small"
                    expand="block"
                  >
                    Submit another
                  </IonButton>
                </div>
              </>
            )}
          </div>
        </IonContent>
      ) : (
        <h2 style={{ paddingTop: "40vh", textAlign: "center" }}>Loading...</h2>
      )}
    </IonPage>
  );
}

export default Submit;
