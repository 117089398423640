import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRoute,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import "../CmsHome.css";
import { add, close } from "ionicons/icons";
import { useEffect, useState } from "react";
import Db from "../../services/db";

interface ActionsProps {
  item: any;
  type: string;
}

function CmsActions(props: ActionsProps) {
  // actions list
  const [actions, setActions] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  // action name
  const [selectedAction, _selectedAction] = useState<any>();
  // action latitude
  const [actionLatitude, setActionLatitude] = useState<any>("");
  // action longitude
  const [actionLongitude, setActionLongitude] = useState<any>("");

  const [actionType, setActionType] = useState("region");

  useEffect(() => {
    if (selectedAction && selectedAction.action_type) {
      setActionType(selectedAction.action_type);
    }
  }, []);

  function ListActions() {
    let list: any = [];
    if (props.item.actions) {
      props.item.actions.map((action: any) => {
        list.push(
          <IonItem
            lines="none"
            button
            onClick={() => {
              _selectedAction(action);
              setShowModal(true);
            }}
          >
            <IonLabel>{action.title}</IonLabel>
          </IonItem>
        );
      });
    }
    return list;
  }

  let id = 0;

  return (
    <>
      <div className="content-card ">
        <IonRow>
          <IonCol>
            <h1>Action </h1>
          </IonCol>
        </IonRow>
        <IonRow>
          {props.item && props.item.region && props.item.region.lat ? (
            <IonCol>
              <div
                style={{
                  borderRadius: 8,
                  overflow: "hidden",
                  backgroundColor: "#eee",
                  padding: 0,
                }}
              >
                <IonList>
                  {props.item &&
                  props.item.actions &&
                  !props.item.actions[0] ? (
                    <IonItem
                      lines="none"
                      button
                      onClick={() => {
                        let a: any = {
                          title: "Check-in",
                          action_type: "region",
                          action_region: {
                            latitude: props.item.region.lat,
                            longitude: props.item.region.lon,
                            radius: 300,
                            identifier: "check-in",
                          },
                          points_earn: 10,
                          button_text: "Check-in",
                          button_complete_text: "Checked-in",
                          action_earn_text: "You earned 10 points",
                          success_text: "You checked-in successfully",
                        };

                        if (props.type == "event") {
                          a.eventId = props.item.id;
                        }

                        if (props.type == "place") {
                          a.placeId = props.item.id;
                        }

                        if (props.type == "post") {
                          a.postId = props.item.id;
                        }

                        _selectedAction(a);
                        setShowModal(true);
                      }}
                    >
                      <IonIcon slot="start" icon={add} />
                      <IonLabel>Create Action</IonLabel>
                    </IonItem>
                  ) : (
                    ListActions()
                  )}
                </IonList>
              </div>
            </IonCol>
          ) : (
            <IonCol>
              <div
                style={{
                  borderRadius: 8,
                  overflow: "hidden",
                  backgroundColor: "#eee",
                  padding: 5,
                  color: "red",
                }}
              >
                Set a location to see actions
              </div>
            </IonCol>
          )}
        </IonRow>
        <IonModal
          isOpen={showModal}
          onDidDismiss={() => {
            setShowModal(false);
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton shape="round" onClick={() => setShowModal(false)}>
                  <IonIcon slot="icon-only" icon={close} />
                </IonButton>
              </IonButtons>
              <IonTitle>{props.item.name}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <h4>
              {selectedAction && selectedAction.id ? "Edit" : "New"} Action
            </h4>
            <IonLabel>Name</IonLabel>
            <IonInput
              className="input"
              placeholder=" name"
              value={selectedAction?.title}
              onIonChange={(e) => {
                let newSelectedAction: any = selectedAction;
                newSelectedAction.title = e.detail.value;
                _selectedAction(newSelectedAction);
              }}
            />
            <IonLabel>Action Type</IonLabel>
            <IonSelect
              className="input"
              value={actionType}
              onIonChange={(e) => {
                setActionType(e.detail.value);
                let newSelectedAction: any = selectedAction;
                newSelectedAction.action_type = e.detail.value;
                _selectedAction(newSelectedAction);
              }}
            >
              <IonSelectOption value="">Select Action Type</IonSelectOption>
              <IonSelectOption value="region">
                Check-In(Location)
              </IonSelectOption>
              <IonSelectOption value="code">Code</IonSelectOption>
              <IonSelectOption value="button">Just a Button</IonSelectOption>
              <IonSelectOption value="show_as_completed">
                Mark As Completed
              </IonSelectOption>
              <IonSelectOption value="upload">Upload</IonSelectOption>
            </IonSelect>

            <IonLabel>Success Text</IonLabel>
            <IonInput
              className="input"
              placeholder="What will replace the button after success"
              value={selectedAction?.button_complete_text}
              onIonChange={(e) => {
                let newSelectedAction: any = selectedAction;
                newSelectedAction.button_complete_text = e.detail.value;
                newSelectedAction.success_text = e.detail.value;
                _selectedAction(newSelectedAction);
              }}
            />
            {actionType == "code" && (
              <>
                <IonLabel>Code</IonLabel>
                <IonInput
                  className="input"
                  placeholder="User must be typed correctly to succeed"
                  value={selectedAction?.code}
                  onIonChange={(e) => {
                    let newSelectedAction: any = selectedAction;
                    newSelectedAction.code = e.detail.value;
                    _selectedAction(newSelectedAction);
                  }}
                />
              </>
            )}
          
            <IonLabel>More Info</IonLabel>
            
            <IonTextarea
              className="input"
              placeholder="Show paragraph after completed"
              value={selectedAction?.body}
              onIonChange={(e) => {
                let newSelectedAction: any = selectedAction;
                newSelectedAction.body = e.detail.value;
                _selectedAction(newSelectedAction);
              }}
            ></IonTextarea>
           <IonItem lines="none">
        <IonLabel>Show more info on success</IonLabel>
        <IonToggle slot="start"
        checked={selectedAction?.displayBodyOnComplete}
          onIonChange={(e) => {
            let newSelectedAction: any = selectedAction;
            console.log(e.detail.checked)
            newSelectedAction.displayBodyOnComplete = e.detail.checked;
            _selectedAction(newSelectedAction);
          }}
        ></IonToggle>
      </IonItem>
            
          </IonContent>
          <IonFooter>
            <IonToolbar style={{ paddingRight: 10, paddingLeft: 10 }}>
              {selectedAction && selectedAction.id ? (
                <>
                  <IonButton
                    slot="start"
                    color="warning"
                    shape="round"
                    onClick={() => {
                      let newPlace = props.item;
                      newPlace.actions = [];
                      Db.UpdatePlace(newPlace).then(() => {
                        //window.location.reload();
                        _selectedAction(null);
                        setShowModal(false);
                      });
                    }}
                  >
                    Delete
                  </IonButton>
                  <IonButton
                    slot="end"
                    color="secondary"
                    shape="round"
                    onClick={() => {
                      setShowModal(false);

                      let a = selectedAction;

                      a.action_region = {
                        latitude: props.item.region.lat,
                        longitude: props.item.region.lon,
                        radius: 300,
                        identifier: "place-location",
                      };

                      if (props.type == "event") {
                        a.eventId = props.item.id;
                      }

                      if (props.type == "place") {
                        a.placeId = props.item.id;
                      }

                      if (props.type == "post") {
                        a.postId = props.item.id;
                      }

                      Db.updateAction(a);
                      // _selectedAction(null);
                    }}
                  >
                    save
                  </IonButton>
                </>
              ) : (
                <IonButton
                  slot="end"
                  color="secondary"
                  shape="round"
                  onClick={() => {
                    setShowModal(false);
                    Db.createAction(selectedAction).then(() => {
                      window.location.reload();
                    });
                    // _selectedAction();
                  }}
                >
                  Create
                </IonButton>
              )}
            </IonToolbar>
          </IonFooter>
        </IonModal>
      </div>
    </>
  );
}

export default CmsActions;
