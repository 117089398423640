import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonMenu,
  IonMenuToggle,
  IonModal,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import "../CmsHome.css";
import { useEffect, useState } from "react";
import { Switch, useHistory } from "react-router";
import Db from "../../services/db";
import { ellipse, ellipseOutline, home, homeOutline, menu, personCircleOutline, thumbsDown, thumbsDownOutline, thumbsUp, thumbsUpOutline } from "ionicons/icons";
import MagicLogin from "../../components/MagicLogin";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCellParams,
  GridToolbar
} from "@mui/x-data-grid";
import moment from "moment";
import CmsSelectLists from "../components/SelectList";

const columns: GridColDef[] = [ 

{ field: "published_at", headerName: "Live", width:80, editable:false,  renderCell: publishedDataGridCol  },
  { field: "id", headerName: "id", width:80, editable: false },
  { field: "name", headerName: "Name", width: 350, editable: false },
  { field: "lists", headerName: "Lists", width: 250, sortable:false, editable: false, valueGetter:listsDataGridCol   },
  { field: "dates", headerName: "Dates", sortable:false, editable: false, valueGetter: datesDataGridCol },
];

function publishedDataGridCol(params:any){
    return params.row.published_at ? (<IonIcon size="large" icon={ellipse} color="secondary" />):(
      <>
      <IonIcon size="large" icon={ellipseOutline} /> 
      </>
    )
}

function listsDataGridCol(params:any){
 return params.row.categories.map((category:any,index:number)=>{
      return category.name
      
 })
}

function datesDataGridCol(params:any){
  return params.row.dates.map((date:any,index:number)=>{
       return moment(date.StartDate).format("M/D/YY")
  })
 }


function Events() {
  const history = useHistory();
  const [items, _items] = useState<any>();
  const [rows, _rows] = useState<GridRowsProp>();
  //showListsModal
  const [showListsModal, _showListsModal] = useState(false);
  const [selectedItem, _selectedItem] = useState<any>();


  useEffect(() => {
    Db.Events().then((data) => {
      console.log("events", data);
      //data.unshift({ id: "new" });
      _rows(data);
    });


  }, []);

  return (
    <>
      <IonContent className="ion-padding admin">
        <div className="content-card first">
          <div>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h1>Events</h1>
                </IonCol>
                <IonCol style={{ textAlign: "right" }}>
                  <IonButton
                  shape="round"
                    onClick={() => {
                      history.push("/hq/event/");
                    }}
                  >
                    Add New
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div style={{ height: "75vh", width: "100%" }}>
            <DataGrid
              onCellClick={(params, event) => {
                if (params) {
                  if (params.field == "id" || params.field == "name") {
                    console.log(params);
                    history.push("/hq/event/" + params.row.id);
                  }
                  if(params.field == "published_at" && !params.row.published_at ){
                    let newRow = params.row
                    let now = new Date();
                    newRow.published_at = now
                    Db.UpdateEvent(newRow);
                  }
                  if(params.field == "lists"){
                    _selectedItem(params.row)
                   _showListsModal(true)
                  }
                }
              }}
              onCellEditStop={(params, event) => {
                console.log(params);
               
                  Db.UpdateEvent(params.row).then((data) => {
                    console.log("updated", data);
                  });
              
                
              }}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              rows={rows ? rows : []}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'published_at', sort: 'asc' },{ field: 'id', sort: 'desc' }],
                },
              }}
              experimentalFeatures={{ newEditingApi: true }}
              getRowClassName={(params) => {
                if (params.row.published_at == null) {
                  return "unpublished";
                } else {
                  return "published";
                }
              }}
            />
          </div>
        </div>
              <CmsSelectLists 
              type="event"
              item={selectedItem} 
              showListsModal={showListsModal} 
              closeModal={()=>{
                _showListsModal(false)
              }} 
              updateItem={(item)=>{
                _selectedItem(item)
              }}
              />
      </IonContent>
    </>
  );
}

export default Events;
