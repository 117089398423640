import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import "./Home.css";

import appleicon from "../assets/img/appleicon.png";
import androidicon from "../assets/img/androidicon.png";
import { useEffect, useState } from "react";
import Db from "../services/db";

function Download() {
  const [city, _city] = useState<any>();

  useEffect(() => {
    Db.City().then((data: any) => {
      //console.log(data)
      _city(data);
    });
  }, []);

  if (city && city.name) {
    return (
      <>
        <div style={{ textAlign: "center", width: "100%" }}>
          <h1>{city.name}</h1>
        </div>

        <div>
          <br />
          <div>
            <div style={{ textAlign: "center" }} className="col">
              <img style={{ width: 100, borderRadius: 5 }} src={androidicon} />
              <br />
              Android
              <br />
              <IonButton
                href={city?.appStore?.google}
                className="btn btn-outline-info "
                role="button"
                shape="round"
                color="secondary"
              >
                DOWNLOAD ON PLAY STORE
              </IonButton>
            </div>
            <div
              style={{ textAlign: "center", paddingTop: 20 }}
              className="col"
            >
              <img style={{ width: 85, borderRadius: 5 }} src={appleicon} />
              <br />
              iPhone
              <br />
              <IonButton
                href={city?.appStore?.apple}
                className="btn btn-outline-info "
                role="button"
                shape="round"
                color="secondary"
              >
                DOWNLOAD ON APP STORE
              </IonButton>
            </div>
             <div style={{ textAlign: "center", paddingTop: 50 }} className="col">
              <div onClick={()=>{
                window.location.href = "/app/submit"
              }} >
             
            <h3 style={{backgroundColor:"#eee", padding:5}}>Submit Something</h3>

              </div>
           
          </div>
          </div>
         
          <div>

          </div>
        </div>
      </>
    );
  } else {
    return <div>Loading...</div>;
  }
}

export default Download;
