import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import "../CmsHome.css";
import { useEffect, useState } from "react";
import { Switch } from "react-router";
import Db from "../../services/db";
import { home, homeOutline, menu, personCircleOutline } from "ionicons/icons";

import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCellParams,
} from "@mui/x-data-grid";

function IntegrationDisplay() {
  const [token, _token] = useState<string>();
  const [showToken, _showToken] = useState<boolean>(false);
  const [destinationId, _destinationId] = useState<string>();

  useEffect(() => {
    _destinationId(Db.getDestination());
    console.log("Page", "Integrations");
    // Get did token from magic login
    Db.getDidToken().then((didToken) => {
      if (didToken) {
        _token(didToken);
      }
    });
  }, []);

  return (
    <>
      <IonContent className="ion-padding admin">
        <div style={{ height: 60 }}></div>
        <div
          style={{ backgroundColor: "white", borderRadius: 10, padding: 20 }}
        >
          <div>
            <h1>Digital Sign Setup</h1>
          </div>
          <div style={{  width: "100%", marginTop: 20 }}>
         <div style={{}}>
                    Digital signage requires an Amazon Fire Stick. Follow this link to add the Nebraska App to your Amazon Fire Stick.
         </div>
          <div style={{paddingTop:20}}>
             <IonButton shape="round" size="small" href="https://www.amazon.com/gp/product/B0B6HFLKS9" target="_blank">Amazon App Store Link</IonButton>
          </div>
          <div>
            <img src="/assets/displayStep1.png" alt="fire stick" style={{width: "300px"}} />
<br/><br/>
            Use the dropdown to select the desired Fire stick from your account. Then click the "Deliver" button. <br/><br/>
            After a few seconds, the Nebraska App will be installed on your Fire stick. and you will be able to use the app to configure your digital signage.

          </div>
          </div>  
        
        </div>
      </IonContent>
    </>
  );
}

export default IntegrationDisplay;
