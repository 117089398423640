import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Db from "../services/db";
import "./Home.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useLocation, useParams } from "react-router";
import { arrowBack, calendar, close, download } from "ionicons/icons";
import {isMobile} from 'react-device-detect';

const localizer = momentLocalizer(moment);

function CalendarView() {
  const [city, _city] = useState<any>();
  const [list, _list] = useState<any>();
  const [selectedItem, _selectedItem] = useState<any>();
  const [modal, _modal] = useState<boolean>(false);
  const { id } = useParams<any>();

  const {search} = useLocation<any>();
  const params = new URLSearchParams(search);
  const destinationId = params.get('destination');
  const showButtons = params.get('buttons');

  useEffect(() => {
    Db.City().then((data: any) => {
      //console.log(data)
      _city(data);
    });

    

    Db.Category(id).then((data: any) => {
      console.log("list", data.linkedEvents);
      let events = data.linkedEvents.map((event: any) => {
        return {
          id: event.id,
          start: moment(event.startDate).toDate(),
          end: moment(event.endDate).toDate(),
          title: event.name,
          description: event.description,
          image: event.heroImage.url,
          url: event.website,
        };
      });
      _list(events);
    });
  }, []);
  return (
    <IonPage>
      <IonContent fullscreen>
        <div>
          <Calendar
            onSelectEvent={(event: any) => {
              _modal(true);
              _selectedItem(event);
            }}
            localizer={localizer}
            events={list}
            defaultView={isMobile ? "agenda" : "month"}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "calc(100vh - 200px)" }}
          />
 
        </div>
        <IonModal
          isOpen={modal}
          onDidDismiss={() => {
            _modal(false);
          }}
        >
          <IonContent>
            <div
              style={{
                width: "100%",
                backgroundColor: "#ccc",
                borderRadius: "0px 0px 30px 30px",
                height: 400,
                backgroundImage: "url(" + selectedItem?.image + ")",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <div style={{ padding: 20 }}>
              <h2>{selectedItem?.title}</h2>
              <b>{selectedItem && selectedItem.start && moment(selectedItem.start).format("lll")} - {selectedItem && selectedItem.end && moment(selectedItem.end).format("lll")}</b>
              <p>{selectedItem?.description}</p>
            </div>
    
          </IonContent>        <IonFooter>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    {selectedItem?.url && (
                      <IonButton color="secondary" shape="round" href={selectedItem?.url} target="_blank">
                        Visit Website
                      </IonButton>
                    )}
                  </IonCol>
                  <IonCol style={{textAlign:"right"}}>
                    <IonButton
                      shape="round"
                      color="dark"
                      fill="clear"
                      onClick={() => {
                        _modal(false);
                      }}
                    >
                      <IonIcon slot="start" icon={close} />
                      close
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonFooter>
        </IonModal>
       
      </IonContent> 
      {destinationId && showButtons != "false" ? (
             <IonFooter>
             <IonGrid>
               <IonRow>
                 <IonCol>
                   <IonButton
                     shape="round"
                     href={"/app/submit?destination=" + destinationId}
                     size="large"
                     color="light"
                     expand="block"
                   >
                     <IonIcon icon={calendar}></IonIcon>
                     &nbsp; Submit Something
                   </IonButton>
                 </IonCol>
                 <IonCol>
                   <IonButton
                     shape="round"
                     href="/app/download"
                     size="large"
                     color="secondary"
                     expand="block"
                   >
                     <IonIcon icon={download}></IonIcon>
                     &nbsp; Download The App
                   </IonButton>
                 </IonCol>
               </IonRow>
             </IonGrid>
           </IonFooter>
       ):null}
    </IonPage>
  );
}

export default CalendarView;
