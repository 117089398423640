import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import MagicLogin from '../components/MagicLogin';
import PulseForm from '../components/PulseForm';
import Db from '../services/db';
import './Home.css';

function Survey(){
const [formData, _formData] = useState<any>();
const { id } = useParams<any>();

const {search} = useLocation<any>();
const params = new URLSearchParams(search);
const codeId = params.get('code'); // bar
 
useIonViewDidEnter(()=>{
Db.Survey(id).then((data:any)=>{
  let newData = data
  if(codeId){
    newData.codeId = codeId
  }
  console.log("newData",newData)
  _formData(newData)
})
})

  return (
    <IonPage>
      <IonHeader>

      </IonHeader>
      <IonContent fullscreen >
        {formData ?(
          <PulseForm data={formData} />   
          ) :null}
      </IonContent>
    </IonPage>
  );
};

export default Survey;
