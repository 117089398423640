import {
  IonButton,
  IonContent,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { Importer, ImporterField } from 'react-csv-importer';
import Db from "../../services/db";

import "../CmsHome.css";
import 'react-csv-importer/dist/index.css';


export default function IntegrationImport() {
  const [token, _token] = useState<string>();
  const [importType, _importType] = useState<string>('');
  const [destinationId, _destinationId] = useState<string>();

  useEffect(() => {
    _destinationId(Db.getDestination());
    console.log("Page", "Integrations");
    // Get did token from magic login
    Db.getDidToken().then((didToken) => {
      if (didToken) {
        _token(didToken);
      }
    });
  }, []);

function submitPlace(item:any){
  let data = {
    name: item.name,
    description: item.description,
    address1: item.address1,
    address2: item.address2,
    city: item.city,
    state: item.state,
    zip: item.zip,
    phone: item.phone,
    website: item.website,
    imageUrl: item.imageUrl,
    destinations: [destinationId]
  }

  Db.DraftPlace(data).then((data:any)=>{}).catch((err:any)=>{});
}

function submitEvent(item:any){
  let data = {
    name: item.name,
    description: item.description,
    address1: item.address1,
    address2: item.address2,
    city: item.city,
    state: item.state,
    zip: item.zip,
    phone: item.phone,
    website: item.website,
    contactName: item.contactName,
    contactEmail: item.contactEmail,
    imageUrl: item.imageUrl,
    dates: [{
      StartDate: item.startDate,
      EndDate: item.endDate,
    }],
    destinations: [destinationId]
  }

  Db.DraftEvent(data).then((data:any)=>{}).catch((err:any)=>{});
}


  return (
    <>
      <IonContent className="ion-padding admin">
        <div style={{ height: 60 }}></div>
        <div
          style={{ backgroundColor: "white", borderRadius: 10, padding: 20 }}
        >
          <div>
            <h1>Import CSV</h1>
          </div>
          <div style={{  width: "100%", marginTop: 20 }}>
         <div style={{marginBottom:50}}>
                    Import CSV file to submit items in bulk to HQ.  All items will be a draft. You will need to publish and add them to a list to make them available to the public. 
         </div>
        </div>

          <div style={{ width: "100%", marginTop: 20 ,marginBottom:50}}>
            <IonButton onClick={()=>{
              _importType('places');
            }} shape="round"> Import Places</IonButton>
            <IonButton onClick={()=>{
              _importType('events');
            }} shape="round"> Import Events</IonButton>
          </div>

 {importType == 'places' &&  
 <>
 <div style={{textAlign:"center"}}>
 <h3>Importing Places</h3>   
 </div>
<Importer
  assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
  restartable={false} // optional, lets user choose to upload another file when import is complete
  onStart={({ file, preview, fields, columnFields }) => {
    // optional, invoked when user has mapped columns and started import
   // prepMyAppForIncomingData();
  }}
  processChunk={async (rows, { startIndex }) => {
    // required, may be called several times
    // receives a list of parsed objects based on defined fields and user column mapping;
    // (if this callback returns a promise, the widget will wait for it before parsing more data)
    for (let row of rows) {
      await submitPlace(row);
    }
  }}
  onComplete={({ file, preview, fields, columnFields }) => {
    // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
    // showMyAppToastNotification();
  
  }}
  onClose={({ file, preview, fields, columnFields }) => {
    // optional, if this is specified the user will see a "Finish" button after import is done,
    // which will call this when clicked
     _importType('');
  }}

  // CSV options passed directly to PapaParse if specified:
  // delimiter={...}
  // newline={...}
  // quoteChar={...}
  // escapeChar={...}
  // comments={...}
  // skipEmptyLines={...}
  // delimitersToGuess={...}
  // chunkSize={...} // defaults to 10000
  // encoding={...} // defaults to utf-8, see FileReader API
>
  <ImporterField name="name" label="Name" />
  <ImporterField name="description" label="Description" />
  <ImporterField name="imageUrl" label="Image Url" />
  <ImporterField name="address1" label="Address Line 1" optional />
  <ImporterField name="address2" label="Address Line 2" optional />
  <ImporterField name="city" label="city" optional />
  <ImporterField name="state" label="state" optional />
  <ImporterField name="zip" label="Zip Code" optional />
  <ImporterField name="website" label="Website" optional />
  <ImporterField name="Phone" label="Phone" optional />
</Importer>
</>
}

{importType == 'events' &&  
<>
<div style={{textAlign:"center"}}>
<h3>Importing Events</h3>
</div>
<Importer
  assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
  restartable={false} // optional, lets user choose to upload another file when import is complete
  onStart={({ file, preview, fields, columnFields }) => {
    // optional, invoked when user has mapped columns and started import
   // prepMyAppForIncomingData();
  }}
  processChunk={async (rows, { startIndex }) => {
    // required, may be called several times
    // receives a list of parsed objects based on defined fields and user column mapping;
    // (if this callback returns a promise, the widget will wait for it before parsing more data)
    for (let row of rows) {
      await submitEvent(row);
    }
  }}
  onComplete={({ file, preview, fields, columnFields }) => {
    // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
    // showMyAppToastNotification();
  }}
  onClose={({ file, preview, fields, columnFields }) => {
    // optional, if this is specified the user will see a "Finish" button after import is done,
    // which will call this when clicked
    _importType('');
  }}

  // CSV options passed directly to PapaParse if specified:
  // delimiter={...}
  // newline={...}
  // quoteChar={...}
  // escapeChar={...}
  // comments={...}
  // skipEmptyLines={...}
  // delimitersToGuess={...}
  // chunkSize={...} // defaults to 10000
  // encoding={...} // defaults to utf-8, see FileReader API
>
  <ImporterField name="name" label="Name" />
  <ImporterField name="description" label="Description" />
  <ImporterField name="imageUrl" label="Image Url" />
  <ImporterField name="address1" label="Address Line 1" optional />
  <ImporterField name="address2" label="Address Line 2" optional />
  <ImporterField name="city" label="city" optional />
  <ImporterField name="state" label="state" optional />
  <ImporterField name="zip" label="Zip Code" optional />
  <ImporterField name="website" label="Website" optional />
  <ImporterField name="Phone" label="Phone" optional />
  <ImporterField name="contactName" label="Primary Contact Name" optional />
  <ImporterField name="contactEmail" label="Primary Contact Email" optional />
  <ImporterField name="startDate" label="Start Date" optional />
  <ImporterField name="endDate" label="End Date" optional />
</Importer>
</>   
}
        </div>
      </IonContent>
    </>
  );
}

