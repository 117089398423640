import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import "../CmsHome.css";
import { useEffect, useState } from "react";
import { Switch } from "react-router";
import Db from "../../services/db";
import { home, homeOutline, menu, personCircleOutline } from "ionicons/icons";

import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCellParams,
} from "@mui/x-data-grid";

function IntegrationZapier() {
  const [token, _token] = useState<string>();
  const [showToken, _showToken] = useState<boolean>(false);
  const [destinationId, _destinationId] = useState<string>();

  useEffect(() => {
    _destinationId(Db.getDestination());
    console.log("Page", "Integrations");
    // Get did token from magic login
    Db.getDidToken().then((didToken) => {
      if (didToken) {
        _token(didToken);
      }
    });
  }, []);

  return (
    <>
      <IonContent className="ion-padding admin">
        <div style={{ height: 60 }}></div>
        <div
          style={{ backgroundColor: "white", borderRadius: 10, padding: 20 }}
        >
          <div>
            <h1>Zapier</h1>
            Here you can find information on how to integrate with other systems
          </div>
          <div style={{  width: "100%", marginTop: 20 }}>
            <h1>
              Destination ID: <b>{destinationId && destinationId}</b>
            </h1>
            <IonButton
              size="small"
              shape="round"
              onClick={() => _showToken(!showToken)}
            >
              Show API Key
            </IonButton>
            {showToken && (
              <div
                style={{
                  border: "#eee solid 2px",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <div style={{}}>
                  {token ? (
                    <>
                      <IonButton
                        color="light"
                        size="small"
                        shape="round"
                        onClick={() =>
                          // Copy token to clipboard
                          navigator.clipboard.writeText(token).then(() => {
                            alert("Token copied to clipboard");
                          })
                        }
                      >
                        Copy Key
                      </IonButton>
                    </>
                  ) : (
                    "No Token"
                  )}
                </div>
                {token}
              </div>
            )}
          
          </div>  
          <div>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonCard style={{backgroundColor:"#fff"}}>
                      <img src={"/assets/zapier.jpg"} />
                      <IonCardContent>
                        Zapier empowers you to automate your work across 5,000+ apps—so you can move forward, faster.
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol></IonCol>
                  <IonCol></IonCol>
                  <IonCol></IonCol>
                </IonRow>
              </IonGrid>
            </div>
        </div>
      </IonContent>
    </>
  );
}

export default IntegrationZapier;
