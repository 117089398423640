import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import Db from '../services/db';


function RedeemAuth(){
  const [city,_city] = useState<any>();


  useEffect(()=>{
    Db.City().then((data:any)=>{
      //console.log(data)
      _city(data)
    })
  },[])
  return (
    <IonPage>
      <IonHeader>

      </IonHeader>
      <IonContent fullscreen >
        Auth
      </IonContent>
    </IonPage>
  );
};

export default RedeemAuth;
