import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import Db from '../services/db';
import './Home.css';

function Error404(){
  const [city,_city] = useState<any>();


  useEffect(()=>{
    Db.City().then((data:any)=>{
      //console.log(data)
      _city(data)
    })
  },[])
  return (
    <IonPage>
      <IonHeader>
      
      </IonHeader>
      <IonContent fullscreen >
        <div style={{textAlign:"center",paddingTop:150}}>
           404 - page not found!
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Error404;
