import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonViewWillLeave,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import Db from "../services/db";
import _ from "lodash";
import "./ListTv.css";
import moment from "moment";
import { arrowBack, arrowBackCircleOutline, colorFill } from "ionicons/icons";  
import { QRCode } from "react-qrcode-logo";




let inactiveTime: any;
let minutes = 10
let speed = 100

function ListTv(props: any) {
  const [city, _city] = useState<any>();
  const [list, _list] = useState<any>();
  const [items, _items] = useState<any>();
  const [destination, _destination] = useState<any>();
  const [selectedItem, _selectedItem] = useState<any>();
  const [interval, _interval] = useState<any>();

  
  const scrollRef = useRef<HTMLDivElement>(null);


  

  const history = useHistory();
  const { id } = useParams<any>();

  // function resetTimer(timer:any) {
  //   console.log("timereset")
  //   clearTimeout(timer);
  //   inactiveTime = setTimeout(() => {
  //     selectNextEvent();
  //   }, minutes * 60 * 1000);
  // }


  useEffect(() => {

    let localDestination = localStorage.getItem("destination")
    if(localDestination){
    let localDestinationData = JSON.parse(localDestination);
       _destination(localDestinationData)

    Db.Destinations().then(data=>{
      for(let d of data){
        if(localDestinationData.id == d.id){
          localStorage.setItem("destination",JSON.stringify(d))
        }
      }
    })
    }

    Db.City().then((data: any) => {
      _city(data);
    });

    Db.ScreenLayout().then((data: any) => {
      if(data && data.ListTimeout){
         minutes = data.ListTimeout
      }
      if(data && data.speed){
        speed = data.speed
      } 
      // resetTimer(inactiveTime)
    });
    if(id){
    Db.Category(id).then((data: any) => {
      console.log("list", data);
      let listThings:any = [];

      for (let e of data.linkedEvents) {
        if (moment() <= moment(e.endDate)) {
          listThings.push(e);
        }
      }
      for (let p of data.linkedPlaces) {
        listThings.push(p);
      }
      for (let p of data.linkedPosts) {
        listThings.push(p);
      }

      _list(data);
      let things = _.orderBy(_.orderBy(listThings, "name"), "startDate");
      _items(things);
      _selectedItem(things[0]);
      console.log("listThings", listThings);

      
     let vpheight = document.documentElement.clientHeight * 0.3;
     let viewHeight = document.documentElement.clientHeight;
     // console.log("viewHeight",viewHeight,"vpheight",vpheight)
    let length = listThings.length * vpheight;  
    let position = 0
    _interval( setInterval(()=>{
        position += 1;
        if(scrollRef.current) {
          scrollRef.current.scrollBy(0,1);
          }
          let itemid = position/(_.round(vpheight)+8)
          
          if(Number.isInteger(itemid)){  
            let item = things[itemid];
            _selectedItem(item)
            // console.log("Item", item)
            // console.log("selectedItem",id)
          }
        

          if(position >= length){
            
              position = 0;
              _selectedItem(things[0])
             
                if(scrollRef.current) {
                  scrollRef.current.scrollTo(0,0);
                  }
             
            }
           // console.log("position", position , "length", length, "id", id)
      },speed) 
    )

    setTimeout(()=>{
      window.location.reload();
    },24 * 60 * 60 * 1000);

    }); 
    }
  
    return function cleanup(){
       //clearInterval(interval) 
    }
  }, []);

 


  function List() {
    return items.map((item: any) => {

      let heroImage = item.heroImage?.url
      if(item.heroImage && item.heroImage.formats && item.heroImage.formats.small){
        heroImage =  item.heroImage.formats.small.url
      }


      return (
        <div className="ListTv_items_item">
               <div className="ListTv_items_item_dates">    
               <div style={{textAlign:"right", fontWeight:"bold",width:"4vw"}}>
                {item.startDate ? moment(item.startDate).format("MMM") : null}
                </div>
                <div style={{textAlign:"right", fontWeight:"bold", fontSize:"4vw",width:"4vw", paddingRight:40}}>
                {item.startDate ? moment(item.startDate).format("D") : null}
                </div>    
            
              </div>
        <div
          onClick={() => {
            _selectedItem(item);
          }}
        
          className="ListTv_items_item_box"
          style={{ backgroundImage: heroImage ? ("url(" + heroImage + ")") : "" }}
        >
          <div className="ListTv_items_item_title_box_gradient">
            <div className="ListTv_items_item_title_box">
              <div className="ListTv_items_item_title">{item.name}</div>
         
            </div>
          </div>
        </div>
        </div>
      );
    });
  }

  if(!id){
    return (
      <>Need ID!!</>
    )
  }else{
     if (list && list.name) {
    return (
      <IonPage >
        <IonHeader></IonHeader>
        <IonContent id="listtv" fullscreen>
          <div onMouseMove={()=>{
            //resetTimer(inactiveTime)
          }} 
          
          onMouseDown={()=>{
            //resetTimer(inactiveTime)
          }}
          style={{  height: "100vh" }}>
            <IonGrid no-padding>
              <IonRow no-padding>
                <IonCol size="4"  style={{margin:"-8px 0px -16px 0px"}} no-padding>
                  
                <div  className="ListTv_items_box" tabIndex={-1} ref={scrollRef} >
                
                  <div className="ListTv_items_list"  >{items ? <><List /><List /></> : null}
                  <div id="ifScroll-container" style={{paddingLeft:"5.5vw",marginTop:100,color:"white", zIndex:9999}}>
                    <IonButton shape="round" expand="block" size="small" 
                      onKeyDown={(e)=>{
                        if(e.keyCode == 13 || e.key == "Enter"){
                          window.location.href = "/app/tvapp"
                          localStorage.removeItem("selectedList")
                        }
                       
                      }}
                    >Change List</IonButton>
                  </div>
                    </div>
                </div>
                </IonCol>
                <IonCol size="8" no-padding>
                  <IonRow>
                    <IonCol>
                         <div
                    className="ListTv_item_image_box"
                    style={{ backgroundImage: selectedItem?.heroImage ? ( "url(" + selectedItem?.heroImage.url + ")" ):""}}
                  >
                  </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                    <div className="ListTv_item_details_box">
                    {selectedItem ? (
                      <>
                        <div className="ListTv_item_details_title">
                          {selectedItem.name}
                        </div>
                        {selectedItem.startDate ? (
                          <div className="ListTv_item_details_dates">
                            <div className="ListTv_item_details_dates_box">
                              <div className="ListTv_item_details_dates_title">
                                from
                              </div>
                              <div className="ListTv_item_details_dates_date">
                                {moment(selectedItem.startDate).format("l")}
                              </div>
                              <div className="ListTv_item_details_dates_title">
                              {selectedItem.hideTimes != true ? (
                                   moment(selectedItem.startDate).format(
                                    "h:mm:ss a"
                                  )
                                  )
                                 : null}
                              </div>
                            </div>
                            <div className="ListTv_item_details_dates_box">
                              <div className="ListTv_item_details_dates_title">
                                to
                              </div>
                              <div className="ListTv_item_details_dates_date">
                                {moment(selectedItem.endDate).format("l")}
                              </div>
                              <div className="ListTv_item_details_dates_title">
                              {selectedItem.hideTimes != true ? (
                                   moment(selectedItem.endDate).format(
                                    "h:mm:ss a"
                                  )
                                  )
                                 : null}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="ListTv_item_details_address">
                          <IonRow>
                            <IonCol>
                          {selectedItem?.address1}
                          <br />
                          {selectedItem.address2 ? (
                            <>
                              {selectedItem.address2}
                              <br />
                            </>
                          ) : null}
                          {selectedItem.city ? (
                            <>{selectedItem.city}, </>
                          ) : null}
                          {selectedItem.state ? (
                            <>{selectedItem.state} </>
                          ) : null}
                          {selectedItem.zip ? <>{selectedItem.zip} </> : null}
                            </IonCol>
                            <IonCol style={{textAlign:"right"}}>
                            {selectedItem.website ? (
                              <div style={{float:"right",width:10,height:10,borderRadius:6,overflow:"hidden",backgroundColor:"white"}}>
                

                                 <QRCode
                                  ecLevel="Q"
                                  value={selectedItem?.website}
                                  eyeRadius={5} // 5 pixel radius for all corners of all positional eyes
                                  qrStyle="dots"
                            
                                  size={80}
                                />
                              </div>
                            ):null}
                            </IonCol>
                          </IonRow>
                         
                        </div>
                        <div className="ListTv_item_details_text">
                          {selectedItem?.shortDescription}
                        </div>
                      </>
                    ) : null}
                  </div>
                    </IonCol>
                  </IonRow>
               
                </IonCol>
              </IonRow>
              <IonRow >
               
              </IonRow>
            </IonGrid>
          </div>
          {destination && destination.sign && destination.sign.cornerVideo ? (
               <div className="VideoFrame">
            <video autoPlay loop muted width="100%" height="100%"  >
              <source src={destination.sign.cornerVideo.url} type="video/mp4" />
            Your browser does not support the video tag.
            </video>
          </div>
          ):null}
       
        </IonContent>
      </IonPage>
    );
  } else {
    return (
      <IonPage>
      <IonHeader></IonHeader>
      <IonContent id="listtv" fullscreen>
        <h1 style={{textAlign:"center",color:"white"}}>Loading...</h1>
        </IonContent>
        </IonPage>
    );
  }
  }
 
}

export default ListTv;
