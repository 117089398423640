import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import ExploreContainer from "../components/ExploreContainer";
import Db from "../services/db";
import "../pages/Home.css";

function SiteHome() {
  const [city, _city] = useState<any>();
  const [lists, _lists] = useState<any>();

  useEffect(() => {
    Db.City().then((data: any) => {
      //console.log(data)
      _city(data);

      Db.Categories().then((data: any) => {
        console.log("_layout", data);
        localStorage.setItem("lists", JSON.stringify(data));
        _lists(data);
      });
    });
  }, []);

  function Lists() {
    if (lists) {
      return lists.map((list: any) => {
        return (
          <IonRow style={{ textAlign: "center", paddingTop: 30 }}>
            <IonCol offset="3" size="3">
              <IonButton
                expand="full"
                shape="round"
                color="dark"
                href={"/app/listtv/" + list.id}
              >
                {list.name}
              </IonButton>
            </IonCol>
            <IonCol size="3">
              <IonButton
                expand="full"
                shape="round"
                color="dark"
                href={"/app/listtvportrait/" + list.id}
              >
                {list.name}
              </IonButton>
            </IonCol>
          </IonRow>
        );
      });
    } else {
      return (
        <h3 style={{ textAlign: "center", paddingTop: 30 }}>Loading ...</h3>
      );
    }
  }

  return (
    <IonPage>
      <IonHeader></IonHeader>
      <IonContent fullscreen>
        <div
          className="background"
          style={{
            height: "55vh",
            width: "100%",
            backgroundImage: "url(" + city?.heroImage.url + ")",
          }}
        >
            <div className="heroTextDiv">
          <div className="heroText">Discover Nebraska</div>
       
          </div>
          <div className="heroSubDiv">
          <div className="heroSub">Discover Nebraska</div>
       
          </div>
        </div>
        
        <IonGrid style={{ textAlign: "center", paddingTop: 30 }}></IonGrid>
        <IonGrid style={{ textAlign: "center", paddingTop: 10 }}>
          <Lists />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}

export default SiteHome;
