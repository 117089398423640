import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import Db from '../services/db';
import './Home.css';
import appleicon from "../assets/img/appleicon.png";
import androidicon from "../assets/img/androidicon.png";

function QrCodeScan(){
  const [city,_city] = useState<any>();
  const [code,_code] = useState<any>();
  const { id } = useParams<any>();

  const history = useHistory();

  

  useEffect(()=>{

   

    Db.City().then((data:any)=>{
      //console.log(data)
      _city(data)
    })
    if(id){
      Db.Scan(id).then(codeData=>{
        localStorage.setItem("lastCode", JSON.stringify(codeData))
        console.log(codeData)
        if(codeData && codeData.survey && codeData.survey.id){
          window.location.href = "/app/survey/" + codeData.survey.id + "?code="+ id;
        }else if(codeData && codeData.link){
          window.location.href = codeData.link;
        }else{
          _code(codeData)
        }
      })
    }
  },[])


  
  return (
    <>
    {code ? (
      <>
    <div style={{textAlign:"center"}}>
    <h1>
    CODE: <b>{id}</b>
    </h1>
    </div>
    <div style={{ textAlign: "center", width: "100%", marginBottom:-10, marginTop:10 }}>
    DOWNLOAD APP
    </div>
    <div>
      <br />
      <div style={{textAlign:"center"}}>
        <a  href={city?.appStore?.google} style={{color: "black", textDecoration: "none" }}>
        <div style={{ textAlign: "center" , display:"inline-block" , margin:"10px 50px 0px 50px"}} className="col">
            <img style={{ width: 50, borderRadius: 5 }} src={androidicon} />
          <br />
          Android
          <br />
        
        </div>
        </a>
        <a  href={city?.appStore?.apple} style={{color: "black", textDecoration: "none" }}></a>
        <div style={{ textAlign: "center",display:"inline-block" , margin:"10px 50px 0px 50px"}} className="col">
          
          <img style={{ width: 44, borderRadius: 5 }} src={appleicon} />
          <br />
          iPhone
          <br />
         
        </div>
      </div>
    </div>
    </>
    ):null}
  </>
  );
};

export default QrCodeScan;
