import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonModal,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import "./cms/CmsHome.css";
import { useEffect, useRef, useState } from "react";
import { Switch } from "react-router";
import Db from "./services/db";
import { home, homeOutline, menu, personCircleOutline } from "ionicons/icons";
import MagicLogin from "./components/MagicLogin";
import List from "./cms/pages/List";
import ListTv from "./pages/ListTv";
import Tv from "./pages/Tv";
import ListTvPortrait from "./pages/ListTvPortrait";
import Embed from "./pages/Embed";
import RedeemAuth from "./redeem/Auth";
import Download from "./pages/Download";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import CmsHome from "./cms/Home";
import { IonReactRouter } from "@ionic/react-router";
import Error404 from "./pages/Error404";
import { Magic } from "magic-sdk";
import Event from "./cms/pages/Event";
import Events from "./cms/pages/Events";
import CmsMenu from "./cms/Menu";
import Places from "./cms/pages/Places";
import Place from "./cms/pages/Place";
import Lists from "./cms/pages/Lists";
import Posts from "./cms/pages/Posts";
import Post from "./cms/pages/Post";
import Integrations from "./cms/pages/Integrations";
import IntegrationZapier from "./cms/pages/IntegrationZapier";
import IntegrationDisplay from "./cms/pages/IntegrationDisplay";
import IntegrationSubmit from "./cms/pages/IntegrationSubmit";
import IntegrationViews from "./cms/pages/IntegrationViews";
import IntegrationImport from "./cms/pages/IntegrationImport";
import DestinationUsers from "./cms/pages/DestinationUsers";
import DestinationUser from "./cms/pages/DestinationUser";

const m = new Magic("pk_live_8F974A4CE6871285"); // ✨

function CmsApp() {
  const [city, _city] = useState<any>();
  const [user, _user] = useState<any>();
  const [showUserModal, _showUserModal] = useState<boolean>(false);
  const userModal = useRef<HTMLIonModalElement>(null);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  var language = window.navigator.language;

  useEffect(() => {
    document.title = "Admin";
    console.log("language", language);

    let localUser = localStorage.getItem("user");

    if (localUser) {
      Db.Me().then((data) => {
        localStorage.setItem("user", JSON.stringify(data));
      });
      console.log("LocalUser", JSON.parse(localUser));
      _user(JSON.parse(localUser));
    }
    Db.City().then((data: any) => {
      //console.log(data)
      _city(data);
    });
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  if (city && city.name) {
    if (user && user.id) {
      if (
        user.editor &&
        user.editor[0] &&
        user.editor[0].destinations &&
        user.editor[0].destinations[0]
      ) {
        return (
          <>
            <IonSplitPane contentId="main-content">
              <IonMenu content-id="main-content">
                <CmsMenu city={city} user={user} />
              </IonMenu>

              <IonPage className="ion-page" id="main-content">
                <IonMenuButton slot="start"></IonMenuButton>
                <IonContent>
                  <IonReactRouter>
                    <IonRouterOutlet>
                      <Route exact path="/hq/home">
                        <CmsHome />
                      </Route>
                      <Route exact path="/hq/embed">
                        <Embed />
                      </Route>
                      <Route exact path="/hq/download">
                        <Download />
                      </Route>
                      <Route exact path="/hq/privacypolicy">
                        <PrivacyPolicy />
                      </Route>
                      <Route exact path="/hq/terms">
                        <Terms />
                      </Route>
                      <Route exact path="/hq/event/:id?">
                        <Event />
                      </Route>
                      <Route exact path="/hq/place/:id">
                        <Place />
                      </Route>
                      <Route exact path="/hq/place/">
                        <Place />
                      </Route>
                      <Route exact path="/hq/places/">
                        <Places />
                      </Route>
                      <Route exact path="/hq/events">
                        <Events />
                      </Route>
                      <Route exact path="/hq/posts">
                        <Posts />
                      </Route>
                      <Route exact path="/hq/post/:id">
                        <Post />
                      </Route>
                      <Route exact path="/hq/post">
                        <Post />
                      </Route>
                      <Route exact path="/hq/lists/">
                        <Lists />
                      </Route>
                      <Route exact path="/hq/list/">
                        <List />
                      </Route>
                      <Route exact path="/hq/list/:id">
                        <List />
                      </Route>
                      <Route exact path="/hq/users/">
                        <DestinationUsers />
                      </Route>
                      <Route exact path="/hq/user/:id">
                        <DestinationUser />
                      </Route>
                      <Route exact path="/hq/integrations/">
                        <Integrations />
                      </Route>
                      <Route exact path="/hq/integration/zapier">
                        <IntegrationZapier />
                      </Route>
                      <Route exact path="/hq/integration/display">
                        <IntegrationDisplay />
                      </Route>
                      <Route exact path="/hq/integration/submit">
                        <IntegrationSubmit />
                      </Route>
                      <Route exact path="/hq/integration/website">
                        <Embed />
                      </Route>
                      <Route exact path="/hq/integration/views">
                        <IntegrationViews />
                      </Route>
                      <Route exact path="/hq/integration/import">
                        <IntegrationImport />
                      </Route>
                      <Route exact path="/hq">
                        <CmsHome />
                      </Route>

                      <Route>
                        <Error404 />
                      </Route>
                    </IonRouterOutlet>
                  </IonReactRouter>
                </IonContent>
                <div
                  onClick={() => {
                    _showUserModal(true);
                  }}
                  style={{
                    position: "fixed",
                    top: 10,
                    right: 10,
                    backgroundColor: "white",
                    borderRadius: 50,
                    paddingLeft: 20,
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        marginTop: -40,
                        verticalAlign: "middle",
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    >
                      {user?.email}
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <IonButtons>
                        <IonButton>
                          <IonIcon
                            slot="icon-only"
                            color="#000"
                            icon={personCircleOutline}
                          ></IonIcon>
                        </IonButton>
                      </IonButtons>
                    </div>
                    <IonModal
                      onDidDismiss={() => {
                        _showUserModal(false);
                      }}
                      isOpen={showUserModal}
                      ref={userModal}
                    >
                      <IonHeader>
                        <div style={{ padding: 20 }}>
                          <h1 style={{ fontWeight: 900 }}> Account</h1>
                        </div>
                      </IonHeader>
                      <IonContent>
                        <div style={{ padding: 20 }}>
                          <div>{user?.email}</div>
                        </div>
                      </IonContent>
                      <IonFooter>
                        <div style={{ float: "left", padding: 20 }}>
                          <IonButton
                            onClick={() => {
                              m.user.logout();
                              localStorage.clear();
                              window.location.reload();
                            }}
                            color="warning"
                            shape="round"
                          >
                            Log Out
                          </IonButton>
                        </div>
                        <div style={{ float: "right", padding: 20 }}>
                          <IonButton
                            onClick={() => {
                              // _showUserModal(false);
                              userModal.current?.dismiss();
                              //alert("close");
                            }}
                            shape="round"
                          >
                            Close
                          </IonButton>
                        </div>
                      </IonFooter>
                    </IonModal>
                  </div>
                </div>
              </IonPage>
            </IonSplitPane>
          </>
        );
      } else {
        return (
          <>
            <h2>You currently are not assigned to be an admin</h2>
            <div>
              <IonButton
                onClick={() => {
                  m.user.logout();
                  localStorage.clear();
                  window.location.reload();
                }}
                color="warning"
                shape="round"
              >
                Log Out
              </IonButton>
            </div>
          </>
        );
      }
    } else {
      return <MagicLogin />;
    }
  } else {
    return null;
  }
}

export default CmsApp;
