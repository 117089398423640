import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRoute,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import "../CmsHome.css";
import { add, close, pin, pinOutline, playCircleSharp } from "ionicons/icons";
import { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import Db from "../../services/db";

interface Props {
  item: any;
  destination: any;
  saved: (data:any)=>any;
}



const defaultProps = {
  center: {
    lat: 41,
    lng: -99
  },
  zoom: 18
};

export default function PickerMap(props: Props) {
  // map point selection
  const [mapPoint, setMapPoint] = useState<any>();
  // map center
  const [mapCenter, setMapCenter] = useState<any>(defaultProps.center);



  useEffect(() => {
    console.log("maps destination", props.destination);
    if(props.item && props.item.region && props.item.region.lat){
      setMapCenter({
        lat: props.item.region.lat,
        lng: props.item.region.lon
      });
      setMapPoint({
        lat: props.item.region.lat,
        lng: props.item.region.lon
      });

    }else if(props.destination.city && props.destination.city.center && props.destination.city.center.lat){
      setMapCenter({lat:parseFloat(props.destination.city.center.lat), lng:parseFloat(props.destination.city.center.lon)});

    }
  },[]);


  // AnyReactComponent overrides the default marker icon.
  function Marker(markerData:any){
    if(markerData.lat && markerData.lng){
      return <div style={{
        color: '#fff',
        background: '#ff0000',
        borderRadius: '50%',
        marginLeft: '-16px',
        marginTop: '-16px',
        width: '30px',
        height: '30px',
        textAlign: 'center',
        lineHeight: '20px',
        border: '1px solid #fff',
        padding: '5px',
        fontSize: '16px'
      }}><IonIcon icon={pin}/></div>;
    }else{
      return null;
    }
  }



function saveLocation(){
  
  let newItem:any = new Object(props.item);
  newItem.region = { 
    name:"center",
    lat: mapPoint.lat,
    lon: mapPoint.lng
  };
  props.saved(newItem.region);
  console.log("saveLocation",newItem);

}
function createMapOptions(){
  return {
    panControl: false,
    mapTypeControl: true,
    mapTypeId:"hybrid"
  }
}


  return (
    <>
    {mapCenter && (
      <>
    <div className="" style={{ borderRadius:10, marginTop:20, overflow:"hidden", height: '320px', width: '100%' }}>
      <GoogleMapReact

        bootstrapURLKeys={{ key: "AIzaSyAxdaCxyt91PRpK_0U-A3la5zBzPhJnsZc" }}
        defaultCenter={defaultProps.center}
        center={mapCenter}
        options={createMapOptions()}
        defaultZoom={defaultProps.zoom}
        onClick={(e) => {
          console.log("event",e.lat,e.lng);
          setMapPoint({lat:e.lat,lng:e.lng});
        } }
      >
        <Marker
          lat={mapPoint?.lat}
          lng={mapPoint?.lng}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
    <div style={{textAlign:"right" }}>

      <IonToolbar>
        <IonButton 
        slot="start"
        size="small"
        color="light"
        shape="round"
        onClick={()=>{
          let searchSting = props.item.address1 +", "+ props.item.city +" " + props.item.state + " " + props.item.zip
          Db.AddressGeoLocation(searchSting).then((data:any)=>{
            console.log("geo location",data);
            let loc = data.results[0].location;
            setMapCenter({lat:loc.lat,lng:loc.lng});
            setMapPoint({lat:loc.lat,lng:loc.lng});
          }).catch((err:any)=>{
            console.log("geo location error",err);
          });

        }}>Move to Address</IonButton>
    
      <IonButton
      disabled={!mapPoint}
      slot="end"
      shape="round"
      size="small"
      color="secondary"
      onClick={() => {
        // Save the map point to the item
        saveLocation();
      }}>
        save location
      </IonButton>
  </IonToolbar>
    </div>
    </>
    )}
    </>
  )
}
