import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import "../CmsHome.css";
import { useEffect, useState } from "react";
import { Switch } from "react-router";
import Db from "../../services/db";
import { home, homeOutline, menu, personCircleOutline } from "ionicons/icons";

import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCellParams,
} from "@mui/x-data-grid";
import { CopyBlock } from "react-code-blocks";

export default function IntegrationViews() {
  const [token, _token] = useState<string>();
  const [showToken, _showToken] = useState<boolean>(false);
  const [destinationId, _destinationId] = useState<string>();

  const [lists, _lists] = useState<any>();
  const [list, _list] = useState<any>(0);
  const [showButtons, _showButtons] = useState<string>('true');

  useEffect(() => {
    let destID = Db.getDestination();
    _destinationId(destID);
    console.log("Page", "Integrations");
    // Get did token from magic login
    Db.getDidToken().then((didToken) => {
      if (didToken) {
        _token(didToken);
      }
    });

    Db.Categories().then((data: any) => {
      let theLists: any = [];

      for (let l of data) {
        if (
          l.destinations &&
          l.destinations[0] &&
          l.destinations[0].id == destID
        ) {
          theLists.push(l);
        }
      }
      if (theLists.length > 0) {
        _lists(theLists);
        _list(theLists[0].id);
      }
    });
  }, []);

  function ListsOptions() {
    if (lists) {
      return lists
        .filter((item: any) => {
          if (destinationId) {
            return (
              item.destinations &&
              item.destinations[0] &&
              item.destinations[0].id === destinationId
            );
          } else {
            return true;
          }
        })
        .map((i: any) => {
          return <option value={i.id}>{i.name}</option>;
        });
    } else {
      return null;
    }
  }

  return (
    <>
      <IonContent className="ion-padding admin">
        <div style={{ height: 60 }}></div>
        <div
          style={{ backgroundColor: "white", borderRadius: 10, padding: 20 }}
        >
          <div>
            <h1>List Views</h1>
          </div>
          <div style={{ width: "100%", marginTop: 20 }}>

            <div style={{}}>
              Select List: &nbsp;
              <select
                value={list}
                onChange={(e) => {
                  _list(e.currentTarget.value);
                }}
                name="cars"
                id="cars"
              >
                <ListsOptions />
              </select>

              &nbsp; &nbsp;Show Buttons: &nbsp;
              <select
                value={showButtons}
                onChange={(e) => {
                  _showButtons(e.currentTarget.value);
                }}
                name="cars"
                id="cars"
              >
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
            <div style={{ marginTop: 30, fontWeight: 600 }}>
              Calendar View: (Link)
            </div>
            <div
              style={{
                backgroundColor: "#FAFAFA",
                border: "#ccc 1px solid",
                borderRadius: 5,
                marginTop: 10,
                marginBottom: 10,
                paddingBottom: 7,
              }}
            >
              <CopyBlock
                text={
                  Db.host +
                  "app/view/calendar/" +
                  list +
                  "?destination=" +
                  destinationId + '&buttons=' + showButtons
                }
                language={"html"}
                showLineNumbers={true}
                theme={"dracula"}
              />
            </div>

            <div style={{ marginTop: 0, fontWeight: 600 }}>
              Calendar View: (Embed)
            </div>
            <div
              style={{
                backgroundColor: "#FAFAFA",
                border: "#ccc 1px solid",
                borderRadius: 5,
                marginTop: 10,
                marginBottom: 30,
                paddingBottom: 7,
              }}
            >
              <CopyBlock
                text={
                  ' <iframe frameBorder="0" src="' +
                  Db.host +
                  "app/view/list/?destination=" +
                  destinationId + '&buttons=' + showButtons +
                  '" width="100%" height="800px"></iframe>'
                }
                language={"html"}
                showLineNumbers={true}
                theme={"dracula"}
              />
            </div>


            <div style={{ marginTop: 30, fontWeight: 600 }}>
              List View: (Link)
            </div>
            <div
              style={{
                backgroundColor: "#FAFAFA",
                border: "#ccc 1px solid",
                borderRadius: 5,
                marginTop: 10,
                marginBottom: 10,
                paddingBottom: 7,
              }}
            >
              <CopyBlock
                text={
                  Db.host +
                  "app/view/list/" +
                  list +
                  "?destination=" +
                  destinationId + '&buttons=' + showButtons 
                }
                language={"html"}
                showLineNumbers={true}
                theme={"dracula"}
              />
            </div>

            <div style={{ marginTop: 0, fontWeight: 600 }}>
              List View: (Embed)
            </div>
            <div
              style={{
                backgroundColor: "#FAFAFA",
                border: "#ccc 1px solid",
                borderRadius: 5,
                marginTop: 10,
                marginBottom: 30,
                paddingBottom: 7,
              }}
            >
              <CopyBlock
                text={
                  ' <iframe frameBorder="0" src="' +
                  Db.host +
                  "app/view/list/?destination=" +
                  destinationId + '&buttons=' + showButtons +
                  '" width="100%" height="800px"></iframe>'
                }
                language={"html"}
                showLineNumbers={true}
                theme={"dracula"}
              />
            </div>
          </div>
        </div>
      </IonContent>
    </>
  );
}
